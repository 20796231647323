import { Box, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import ContainerTitle from '../../../components/common/title/ContainerTitle';
import BorderButton from '../../../components/buttons/BorderButton';
import * as actionCreator from '../../../store/action';
import {
  getAgeFromDob,
  getPreviousDate,
  yyyyMmDd,
} from '../../../utils/timeMethods';
import CustomTextField from '../../../components/muiInputFields.js/CustomTextField';
import React from 'react';
import { connect } from 'react-redux';
import { constant } from '../../../utils/constant';
import EditIcon from '@material-ui/icons/Edit';

const useStyle = makeStyles(theme => ({
  createTaskContainer: {
    width: '100%',
    maxWidth: '800px',
  },
  fieldContainer: {
    position: 'relative',
    borderRadius: '10px',
    background: '#A3CED9',
    padding: '5px 15px',
  },
  titleStyle: {
    color: '#333333',
    fontSize: '1rem',
    fontWeight: 550,
  },
  assignToList: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: 12,
  },
  chipStyle: {
    margin: '0 5px',
    padding: 4,
    border: '1.3px solid #074D5F',
    minWidth: 100,
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
      border: '1.3px solid #888',
    },
  },
  unSelectedChipStyle: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectedChipStyle: {
    backgroundColor: '#A3D4E0',
    '&:hover': {
      backgroundColor: '#A3D4E0',
    },
    '&:focus': {
      backgroundColor: '#A3D4E0',
    },
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12,
  },
  errorStyle: {
    color: 'red',
    fontSize: '0.9rem',
    fontWeight: 500,
    position: 'absolute',
    bottom: -25,
  },
  selectedDateContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  inputFieldStyle: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: -10000,
  },
  uploadChildProfileImageStyle: {
    width: '100%',
    backgroundColor: 'transparent',
    color: '#333',
    // border: '2px solid #359DB6',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  updateImageButton: {
    fontSize: '1.2rem',
    fontWeight: '400',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  imageStyle: {
    width: '33%',
    maxWidth: 33,
    marginRight: '20px',
  },
  addCircleSharpIcon: {
    fontSize: '1.3rem',
    color: '#333',
    marginLeft: 5,
  },
  addGuardianContainerStyle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  guardianListContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  guardianAddedContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1.5px solid #359DB6',
    borderRadius: 18,
    padding: 2,
  },
  guardianInnerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  profileIconStyle: {
    fontSize: '2rem',
    marginRight: '0.5rem',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '2.5rem',
      marginRight: '0.7rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '1.6rem',
    },
  },
  backContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backIconStyle: {
    fontSize: '1rem',
  },
  backTextStyle: {
    fontSize: '1rem',
  },
  verifyTitleContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  verifyTitleTextStyle: {
    color: '#155B6B',
  },
  verifyTitleDescriptionContainerStyle: {
    color: '#155B6B',
    display: 'flex',
    justifyContent: 'center',
  },
  verifyTitleDescriptionTextStyle: {
    textAlign: 'center',
    color: '#155B6B',
    fontSize: '0.8rem',
    maxWidth: 422,
  },
  otpInputBoxContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  otpTextFieldStyle: {
    width: '2.5rem',
    margin: '0 3px',
    backgroundColor: '#359DB6',
    borderRadius: 5,
    textAlign: 'center',
  },
  stage3InputField: {
    backgroundColor: '#359DB6',
  },
  stage3InputWidth: {
    width: '60%',
  },
  centerField: {
    display: 'flex',
    justifyContent: 'center',
  },
  disclaimerText: {
    fontSize: '0.7rem',
  },
}));

const EditChildModal = props => {
  const {
    open,
    handleClose,
    notify,
    getRecommendationLoader,
    recommendationLoader,
    updateChildData,
    getChildList,
    uploadDoc,
    profile,
  } = props;
  const theme = useTheme();
  const classes = useStyle(theme);

  const [fieldData, setFieldData] = React.useState({
    firstName: {
      value: '',
      errorMessage: '',
      required: 'true',
      onChange: e => {
        onChangeTextfieldHandler('firstName', e);
      },
    },
    lastName: {
      value: '',
      errorMessage: '',
      required: 'true',
      onChange: e => {
        onChangeTextfieldHandler('lastName', e);
      },
    },
    email: {
      value: '',
      errorMessage: '',
      type: 'email',
      required: 'true',
      onChange: e => {
        onChangeTextfieldHandler('email', e);
      },
    },
    dob: {
      value: '',
      errorMessage: '',
      type: 'date',
      inputProps: {
        min: yyyyMmDd(getPreviousDate(16)),
        max: yyyyMmDd(new Date()),
      },
      minDate: '2022-10-29',
      maxDate: yyyyMmDd(new Date()),
      required: 'true',
      onChange: e => {
        onChangeTextfieldHandler('dob', e);
      },
    },
    guardianName: {
      value: '',
      errorMessage: '',
      isValidUser: false,
      onChange: e => {
        onChangeTextfieldHandler('guardianName', e);
      },
    },
    guardianRelation: {
      value: '',
      options: [
        { value: 'father', label: 'Father' },
        { value: 'mother', label: 'Mother' },
        { value: 'guardian', label: 'Guardian' },
      ],
      errorMessage: '',
      onChange: e => {
        onChangeTextfieldHandler('guardianRelation', e);
      },
    },
    guardianList: {
      value: [],
      errorMessage: '',
    },
    profileImage: {
      value: '',
      errorMessage: '',
    },
    fileUpload: {
      file: {},
      type: 'file',
      id: 'child-profile-upload-id',
      accept: '.jpeg,.jpg,.png',
    },
  });

  const imageCaptureMethod = e => {
    if (e.target?.files?.[0]?.size <= 5242880) {
      let keyName = e.target.name;
      let imageUrl = URL.createObjectURL(e.target.files[0]);
      let file = e.target.files[0];
      setFieldData(data => {
        let fieldTempData = {};
        fieldTempData['fileUpload'] = { ...data['fileUpload'] };
        fieldTempData['fileUpload'].file = { keyName, imageUrl, file };
        return { ...data, ...fieldTempData };
      });
    } else {
      notify('Document Size should be less than 5 MB', 'warning');
    }
  };
  const onChangeTextfieldHandler = (field, e) => {
    if (typeof e.persist == 'function') e.persist();
    setFieldData(data => {
      let fieldTempData = {};
      fieldTempData[field] = { ...data[field] };
      fieldTempData[field].value = e?.target?.value;
      return { ...data, ...fieldTempData };
    });
  };

  const onChangeTextfieldErrorHandler = (field, message) => {
    setFieldData(data => {
      let fieldTempData = {};
      fieldTempData[field] = { ...data[field] };
      fieldTempData[field].errorMessage = message;
      return { ...data, ...fieldTempData };
    });
  };

  const getRecommends = async searchUser => {
    let reqBody = {
      searchUser: searchUser,
    };
    getRecommendationLoader(true);
    let res = await actionCreator.getRecommendsApi(reqBody);
    getRecommendationLoader(false);
    if (res?.user?.length > 0) {
      updateStatusOfGuardianField(res?.user?.[0]);
    } else {
      updateStatusOfGuardianField(false);
    }
  };

  const updateStatusOfGuardianField = status => {
    setFieldData(data => {
      let fieldTempData = {};
      fieldTempData['guardianName'] = { ...data['guardianName'] };
      fieldTempData['guardianName'].isValidUser = status;
      return { ...data, ...fieldTempData };
    });
  };

  const fileUpoadTrigger = () => {
    let fileInput = document.getElementById('child-profile-upload-id');
    fileInput.click();
  };

  const submitFormdata = async () => {
    let profilePic;
    if (fieldData?.fileUpload?.file?.file) {
      let profilePicResponse = await uploadDoc({
        file: fieldData?.fileUpload?.file?.file,
        key:
          fieldData?.fileUpload?.file?.key ||
          open.userName + (fieldData?.fileUpload?.file?.file?.name || ''),
      });
      if (profilePicResponse.status) {
        profilePic = profilePicResponse.data.uploaded_docs_url[0];
      }
    }

    let data = {
      //   email: fieldData.email.value,
      // firstName: fieldData.firstName.value || null,
      // lastName: fieldData.lastName.value || null,
      // dateOfBirth: fieldData.dob.value || null,
      // guardian: [
      //   ...(open?.guardian || []),
      //   ...(fieldData?.guardianList?.value?.map(data => data.ProfileID) || []),
      // ],
    };

    if (profilePic) {
      data.profile_pic = profilePic;
    }

    let guardianList = [];
    fieldData?.guardianList?.value?.map(data => {
      let request = {
        from: { relation: '', id: open._id },
        to: { id: data.ProfileID, relation: data.Relation },
      };
      guardianList.push(request);
    });

    // changes
    let response = await updateChildData({
      changes: data,
      guardianList: guardianList,
      userName: open.userName,
      profileId: open._id,
      profile_pic: open?.profile_pic,
      //   profile_pic: `${process.env.REACT_APP_SPACE_KEY}/${open?.profile_pic}`,
    });
    if (response.status) {
      notify('Updated', 'success');
      getChildList();
    } else {
      notify('Failed to update', 'error');
    }
    handleClose();
  };

  const onSubmkitHandler = e => {
    e?.preventDefault();
    submitFormdata();
  };

  const addGuardianMethod = () => {
    if (
      fieldData.guardianName.isValidUser &&
      fieldData.guardianRelation.value
    ) {
      let isInclude = fieldData.guardianList?.value.map(
        data =>
          data.ProfileID == fieldData?.guardianName?.isValidUser?.ProfileID,
      );
      if (isInclude?.length) {
        onChangeTextfieldErrorHandler('guardianName', 'Guardian Already Added');
      } else {
        setFieldData(data => {
          let updatedData = {};
          updatedData['guardianList'] = data.guardianList;
          updatedData['guardianList'].value = [
            ...data.guardianList.value,
            {
              ...fieldData?.guardianName?.isValidUser,
              Relation: data.guardianRelation.value,
            },
          ];
          updatedData['guardianName'] = data.guardianName;
          updatedData['guardianName'].value = '';
          updatedData['guardianName'].isValidUser = false;

          updatedData['guardianRelation'] = data.guardianRelation;
          updatedData['guardianRelation'].value = '';
          return { ...data, updatedData };
        });
        onChangeTextfieldErrorHandler('guardianName', '');
      }

      onChangeTextfieldErrorHandler('guardianRelation', '');
    } else {
      if (!fieldData.guardianName.value) {
        onChangeTextfieldErrorHandler('guardianName', 'Guardian is required');
      } else if (fieldData.guardianName.isValidUser) {
        onChangeTextfieldErrorHandler('guardianName', 'Invalid user');
      } else {
        onChangeTextfieldErrorHandler('guardianName', '');
      }

      if (!fieldData.guardianRelation.value) {
        onChangeTextfieldErrorHandler(
          'guardianRelation',
          'Relation is required',
        );
      } else {
        onChangeTextfieldErrorHandler('guardianRelation', '');
      }
    }
  };
  const removeGuardian = guardian => {
    setFieldData(data => {
      let fieldTempData = {};
      fieldTempData.guardianList = { ...data.guardianList };
      fieldTempData.guardianList.value =
        fieldTempData.guardianList.value.filter(
          data => data.ProfileID != guardian.ProfileID,
        );
      return { ...data, ...fieldTempData };
    });
  };
  React.useEffect(() => {
    if (fieldData.guardianName.value) {
      const timeoutId = setTimeout(() => {
        getRecommends(fieldData.guardianName.value);
        updateStatusOfGuardianField(false);
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
  }, [fieldData.guardianName.value]);

  React.useEffect(() => {
    setFieldData(data => {
      let tempDate = { ...data };
      tempDate.firstName.value = open?.firstName;
      tempDate.lastName.value = open?.lastName;
      //   tempDate.email.value = open?.email;
      tempDate.dob.value = open?.dateOfBirth;
      tempDate.profileImage.value = open?.profile_pic;
      return { ...tempDate };
    });
  }, []);

  return (
    <>
      <Box
        className={clsx(
          classes.createTaskContainer,
          'avoid-autofill-color-359DB6',
        )}>
        {open?.kyc?.status ? (
          <Box textAlign={'center'}>
            <Typography className={classes.disclaimerText}>
              *First Name, Last Name and DOB are linked to KYC. If you want to
              update these please contact support:
              <strong> support@principalityofcogito.com</strong>
            </Typography>
          </Box>
        ) : null}

        <form onSubmit={onSubmkitHandler}>
          <Grid
            container
            item
            xs={12}
            spacing={1}
            justifyContent="space-between"
            style={{
              border: '2px solid #359DB6',
              padding: '20px',
              marginTop: '10px',
              borderRadius: '10px',
            }}>
            <Grid
              container
              item
              xs={12}
              spacing={2}
              justifyContent="space-between"
              // style={{ backgroundColor: '#A3CED9', borderRadius: 7 }}
            >
              <Grid item xs={12} sm={6}>
                <Box className={clsx(classes.fieldContainer)}>
                  <ContainerTitle
                    title={'First Name'}
                    className={clsx(classes.titleStyle)}
                  />
                  <CustomTextField
                    {...fieldData.firstName}
                    disabled={open?.kyc?.status}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={clsx(classes.fieldContainer)}>
                  <ContainerTitle
                    title={'Last Name'}
                    className={clsx(classes.titleStyle)}
                  />
                  <CustomTextField
                    {...fieldData.lastName}
                    disabled={open?.kyc?.status}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box className={clsx(classes.fieldContainer)}>
                  <ContainerTitle
                    title={'DOB'}
                    className={clsx(classes.titleStyle)}
                  />

                  <CustomTextField
                    {...fieldData.dob}
                    disabled={open?.kyc?.status}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box
                  className={clsx(classes.fieldContainer)}
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <input
                    {...fieldData.fileUpload}
                    className={classes.inputFieldStyle}
                    onChange={imageCaptureMethod}
                  />
                  {fieldData.fileUpload?.file?.imageUrl ||
                  fieldData.profileImage.value ? (
                    <>
                      <Typography>Upload Child Image</Typography>

                      <Box className={classes.imageContainer}>
                        <img
                          src={
                            fieldData.fileUpload?.file?.imageUrl ||
                            `${process.env.REACT_APP_SPACE_KEY}/${fieldData.profileImage.value}`
                          }
                          alt={fieldData.fileUpload?.file?.file?.name}
                          className={classes.imageStyle}
                          onClick={fileUpoadTrigger}
                          disabled={true}
                        />
                        <text style={{ paddingLeft: '2%' }}>
                          {fieldData.fileUpload?.file?.file?.name}
                        </text>

                        <EditIcon
                          className={classes.updateImageButton}
                          text={'Update Image'}
                          onClick={fileUpoadTrigger}
                          type={'button'}
                        />
                      </Box>
                    </>
                  ) : (
                    <BorderButton
                      style={{ height: '100%' }}
                      type={'button'}
                      text={'Upload Child Image'}
                      className={classes.uploadChildProfileImageStyle}
                      onClick={fileUpoadTrigger}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Box className={clsx(classes.submitContainer)}>
                <BorderButton text={'Update'} type={'submit'} />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};
const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    recommendationLoader: userReducer?.loadings?.getRecommendation,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    notify: (data, variant) => {
      dispatch(actionCreator.notify(data, variant));
    },
    getRecommendationLoader: status => {
      dispatch(actionCreator.setGetRecommendation(status));
    },
    updateChildData: data => actionCreator.updateChildData(data),
    uploadDoc: data => actionCreator.uploadDoc(data),
    getChildList: () => {
      dispatch(actionCreator.getChildList());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditChildModal);
