import React from 'react';
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
} from '@material-ui/core';
import { connect } from 'react-redux';
import * as actionCreator from '../../store/action';
import Calender from '../../assets/images/reward/Calender.png';
import User from '../../assets/images/reward/User.png';
import GiftBox from '../../assets/images/reward/GiftBox.png';
import Line from '../../assets/images/reward/Line.png';
import RewardComponent from './RewardComponent';
import RewardComponent2 from './RewardComponent2';
import CustomDateSelectField from '../../components/muiInputFields.js/CustomDateSelectField';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserView, MobileView } from 'react-device-detect';
import KidRewardCard from '../kidSection/kidReward/KidRewardCard';
import NoRewards from '../../assets/images/rewardsImages/noReward.svg';
import { priceFormatter } from '../../utils/methods';
import moment from 'moment';
const useStyle = makeStyles(theme => ({
  container: {
    padding: '1rem',
    position: 'relative',
  },
  Button: {
    width: '100%',
    height: '1%',
    border: '2px solid #C49C39',
    backgroundColor: 'transparent',
    color: '#C49C39',
    borderRadius: '20px',
    alignItems: 'center',
    padding: '10% 0 10% 0',
    [theme.breakpoints.down('400')]: {
      fontSize: '0.7rem',
    },
  },
  Button2: {
    width: '100%',
    height: '1%',
    border: '2px solid #359DB6',
    backgroundColor: 'transparent',
    color: '#359DB6',
    position: 'relative',
    borderRadius: '20px',
    padding: '10% 0 10% 0',
    [theme.breakpoints.down('400')]: {
      fontSize: '0.7rem',
    },
  },
  rewardBox: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '0%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '10%',
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: '0% !important',
    },
  },
  rewards: {
    height: '60vh',
    overflowY: 'scroll',
    [theme.breakpoints.between('sm', 'md')]: {
      height: '70vh',
    },
  },
  firstContainer: {
    borderRadius: '20px',
    height: '55vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#C9E5EC',
    [theme.breakpoints.between('sm', 'md')]: {
      height: '40vh',
      margin: '5% 0',
      // display: 'flex',
      // flexDirection: 'column-reverse',
      // justifyContent: 'flex-end',
    },
    [theme.breakpoints.down('sm')]: {
      height: '15vh',
    },
  },
  noRewardContainer: {
    borderRadius: '20px',
    margin: '4% 0 0 0',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      margin: '15% 0',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0% 0',
    },
  },
  GiftBox: {
    width: '80%',
    paddingLeft: '20%',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '100%',
      paddingTop: '30%',
      paddingLeft: '0%',
    },
  },
  firstContainerText: {
    marginTop: '10%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '5%',
    },
  },
  dividerLine: {
    textAlign: 'center',
    height: '58vh',
    overflow: 'clip',
    [theme.breakpoints.between('sm', 'md')]: {
      height: '45vh',
      paddingTop: '5%',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '2%',
    },
  },
  Button2OptionContainer: {
    position: 'absolute',
    top: 36,
    left: 0,
    width: '100%',
    padding: 5,
    minHeight: 26,
    borderRadius: 8,
    zIndex: 1,
    backgroundColor: 'rgb(201, 229, 236)',
    border: '0.5px solid rgba(0,0,0,0.2)',
  },
  Button2Option: {
    position: 'relative',
    zIndex: 2,
  },
  Button2OptionBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    zIndex: 1,
  },
  Button2OptionList: {
    display: 'flex',
    alignItems: 'center',
    padding: 3,
    borderRadius: 8,
    marginBottom: 2,
    '&:hover': {
      backgroundColor: '#359DB6',
      color: '#fff',
    },
  },
  NoList: {
    paddingTop: 10,
  },
  SelectedButton2OptionList: {
    backgroundColor: '#359DB6',
    color: '#fff',
  },
  Button2OptionListImg: {
    height: 22,
    width: 22,
    borderRadius: 16,
    marginRight: 5,
  },
  root: {
    marginTop: '0 !important',
  },
  removeElement: {
    display: 'none !important',
  },
  header: {
    paddingBottom: '8px',
    background: 'white !important',
  },
  month: {
    padding: '8px 0',
  },
  selectedMonth: {
    background: 'gray !important',
    padding: '8px 0',
  },
  inputBox: {
    // background: 'white !important',
    border: 'none',
  },
  NoRewards: {
    borderRadius: '20px',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#C9E5EC',
    paddingBottom: '2%',
    [theme.breakpoints.down('sm')]: {
      marginTop: '2%',
    },
  },
  NoRewardImg: {
    width: '65%',
  },
  NoRewardsText: {
    textAlign: 'center',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '1rem',
    margin: '5% 4% 0 2%',
    [theme.breakpoints.only('sm')]: {
      paddingTop: '5%',
      fontSize: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: '5%',
      fontSize: '0.8rem',
    },
  },
  NoRewardsText2: {
    textAlign: 'center',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '1rem',
    [theme.breakpoints.only('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
}));
const Reward = props => {
  const {
    getRewards,
    rewards,
    getChildList,
    childList,
    updateRewards,
    profile,
  } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [childrenOptionDropdown, setChildrenOptionDropdown] =
    React.useState(false);
  const [selectedChild, setSelectedChild] = React.useState();
  const [startDate, setStartDate] = React.useState(new Date());
  const [sum, setSum] = React.useState(0);
  const ref = React.useRef();
  React.useEffect(() => {
    if (
      selectedChild &&
      startDate &&
      startDate?.getMonth() + 1 &&
      startDate?.getFullYear()
    ) {
      getRewards({
        childId: selectedChild._id,
        month: startDate.getMonth() + 1,
        year: startDate.getFullYear(),
      });
    } else if (childList?.length) {
      setSelectedChild(childList[0]);
    }
  }, [selectedChild, childList, startDate]);

  React.useEffect(() => {
    if (!childList?.length) {
      getChildList();
    }
  }, []);

  React.useEffect(() => {
    let sum = 0;
    rewards?.map(data => {
      sum += data?.total_reward || 0;
    });
    setSum(sum);
  }, [rewards]);

  const handleOption = () => {
    setChildrenOptionDropdown(!childrenOptionDropdown);
  };

  const handleSelectedChild = data => {
    setSelectedChild(data);
  };

  const selectDateField = () => {
    document
      .getElementById('hide-datepicker')
      .getElementsByTagName('input')[0]
      .click();
  };
  var selectedChildRelation = selectedChild?.family.filter(familyData => {
    return familyData._id == profile._id;
  })?.[0];

  var reward = sum.toFixed(2);
  return (
    <div
      style={{
        backgroundColor: '#eef7fa',
        padding: '1% 5% 0% 5%',
      }}>
      <Typography
        style={{
          color: 'black',
          fontSize: '1.5rem',
          fontWeight: 'bold',
        }}>
        Rewards
      </Typography>

      <Grid
        container
        item
        xs={12}
        spacing={2}
        style={{ paddingTop: '1%', paddingBottom: '2vh' }}>
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          className={clsx('hide-datepicker')}
          id={'hide-datepicker'}>
          <Button className={clsx(classes.Button)} onClick={selectDateField}>
            <img src={Calender} style={{ padding: '4px' }} />
            <text
            // style={{ paddingRight: '15px' }}
            >
              Date - {moment(startDate).format('MMM, YYYY')}
            </text>
          </Button>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            dateFormat="MMM y"
            showMonthYearPicker
            ref={ref}
            onYearChange={() => {
              const selectedMonth = document.getElementsByClassName(
                'react-datepicker__month-text--keyboard-selected',
              );
              selectedMonth[0].classList.add(classes.selectedMonth);
            }}
            onCalendarOpen={() => {
              const x = document.getElementsByClassName(
                'react-datepicker-popper',
              );
              const y = document.getElementsByClassName(
                'react-datepicker__triangle',
              );
              const z = document.getElementsByClassName(
                'react-datepicker__header',
              );
              const months = document.getElementsByClassName(
                'react-datepicker__month-text',
              );
              const selectedMonth = document.getElementsByClassName(
                'react-datepicker__month-text--keyboard-selected',
              );
              x[0].classList.add(classes.root);
              y[0].classList.add(classes.removeElement);
              z[0].classList.add(classes.header);
              for (let i = 0; i < months.length; i++) {
                months[i].classList.add(classes.month);
              }
              selectedMonth[0].classList.add(classes.selectedMonth);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={2}>
          <Button className={classes.Button2} onClick={handleOption}>
            <img src={User} style={{ padding: '6px' }} />
            <text> Children</text>
            {childrenOptionDropdown ? (
              <Box className={classes.Button2OptionContainer}>
                <Box className={classes.Button2Option}>
                  {childList?.length ? (
                    childList.map(data => {
                      return (
                        <Box
                          className={clsx(
                            classes.Button2OptionList,
                            selectedChild?._id == data?._id
                              ? classes.SelectedButton2OptionList
                              : '',
                          )}
                          key={data._id}
                          onClick={() => handleSelectedChild(data)}>
                          <img
                            src={`${process.env.REACT_APP_SPACE_KEY}/${data?.profile_pic}`}
                            className={clsx(classes.Button2OptionListImg)}
                          />
                          <text>{data?.firstName}</text>
                        </Box>
                      );
                    })
                  ) : (
                    <Box>
                      <text style={{ color: 'red' }}> No children</text>
                    </Box>
                  )}
                </Box>
                <Box className={classes.Button2OptionBackground}></Box>
              </Box>
            ) : null}
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item container display="flex" justifyContent="center">
          {/* rewards points text and image */}
          {matches ? (
            <Grid xs={12} style={{ paddingBottom: '5%' }}>
              <KidRewardCard />
            </Grid>
          ) : (
            <Grid
              flexDirection="column"
              item
              container
              spacing={1}
              xs={12}
              md={4}
              className={classes.firstContainer}>
              <Grid item className={classes.firstContainerText}>
                <Typography
                  style={{
                    textAlign: 'center',
                    color: '#359DB6',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  }}>
                  {` Total Rewards`}
                  <br />
                  <text style={{ color: '#ff725e' }}>{reward} </text>
                  {`COG`}
                </Typography>
              </Grid>
              <Grid item>
                <img style={{ width: '30vw' }} src={GiftBox} />
              </Grid>
            </Grid>
          )}

          <Grid
            item
            container
            xs={12}
            md={8}
            sm={12}
            className={classes.rewardBox}>
            {!matches ? (
              <Grid
                item
                md={1}
                style={{
                  textAlign: 'center',
                  height: '65vh',
                  overflow: 'clip',
                }}>
                <img src={Line} />
              </Grid>
            ) : null}
            <Grid
              item
              container
              xs={12}
              md={10}
              sm={12}
              spacing={2}
              className={classes.rewards}>
              {rewards && sum > 0 ? (
                rewards.map((rewardData, index) => {
                  return (
                    <Grid item key={index}>
                      <RewardComponent rewardData={rewardData} index={index} />
                    </Grid>
                  );
                })
              ) : (
                <Grid
                  item
                  xs={12}
                  className={classes.noRewardContainer}
                  style={{
                    textAlign: 'center',
                  }}>
                  <img src={NoRewards} className={classes.NoRewardImg} />
                  {selectedChild?.firstName == undefined ? (
                    <>
                      <Typography className={classes.NoRewardsText}>
                        Whoops, looks like you have no children added to your
                        profile.
                      </Typography>
                    </>
                  ) : (
                    <>
                      {' '}
                      <Typography className={classes.NoRewardsText}>
                        {`Uh oh, looks like `}
                        <text style={{ color: '#ff725e' }}>
                          {`${selectedChild?.firstName}`}
                        </text>
                        {` doesn’t have any rewards for now.`}
                      </Typography>
                      <Typography className={classes.NoRewardsText2}>
                        {`Help `}
                        {selectedChildRelation?.yourRelation == 'son'
                          ? 'him'
                          : selectedChildRelation?.yourRelation == 'daughter'
                          ? 'her'
                          : 'ward'}
                        {` earn COG by creating a task`}
                      </Typography>
                    </>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    isParentLoggedIn: authReducer.isParentLoggedIn,
    isChildrenLoggedIn: authReducer.isChildrenLoggedIn,
    rewards: userReducer.rewards,
    childList: userReducer.childList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRewards: data => dispatch(actionCreator.getRewardsParent(data)),
    getChildList: () => {
      dispatch(actionCreator.getChildList());
    },
    updateRewards: () => {
      dispatch(actionCreator.updateRewards());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reward);
