import { Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import BorderButton from '../../../components/buttons/BorderButton';
import CommonModel from '../../../components/common/model/CommonModel';
const useStyles = makeStyles(theme => ({
  container: {
    border: '2px solid #359DB6',
    padding: '20px',
    marginTop: '10px',
    borderRadius: '10px',
  },
}));
const RedirectInfoPage = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <>
      <Grid container className={classes.container}>
        <Typography>
          <text>
            To complete your sign up process, you are now going to be redirected
            to the Cogito Metaverse website. Follow the steps there to setup an
            account. Your Pocket Money account with then be ready for use.{' '}
          </text>
          <br /> <br />
          <text> Click ‘Ok’ to continue with your sign up.</text>
        </Typography>
        <Grid item xs={12} style={{ textAlign: 'center', marginTop: '5%' }}>
          <BorderButton
            text="Ok"
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_WEBSITE_HOST}/signup?isPocketMoneySignUp=true`,
                '_self',
              );
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RedirectInfoPage;
