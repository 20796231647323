import {
  Box,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { constant } from '../../../utils/constant';
import BorderButton from '../../../components/buttons/BorderButton';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormikTextField from '../../../components/formikInputFields/FormikTextField';
import BaseButton from '../../../components/buttons/BaseButton';
import * as actionCreator from '../../../store/action';
import Title from './Title';
import FormikTextFieldCustom from '../../../components/formikInputFields/FormikTextFieldCustom';
import { isStringInteger } from '../../../utils/stringMethods';
import { getWithExpiry } from '../../../assets/utility';

const useStyles = makeStyles(theme => ({
  selectionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '0 5%',
    marginTop: 40,
    width: '90%',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      justifyContent: 'center',
      marginTop: 10,
      width: '100%',
    },
  },
  innerSelectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
  },
  backtextStyle: {
    fontSize: 13,
  },
  backIconStyle: {
    fontSize: 18,
  },
  titleFont: {
    fontSize: 22,
    color: '#155B6B',
    fontWeight: 800,
  },
  subTitleFont: {
    fontSize: 14,
    color: '#359DB6',
    fontWeight: 500,
    marginTop: 4,
  },
  titleContainer: {
    marginTop: 16,
    marginBottom: 32,
  },
  buttonContainer: {
    marginTop: 16,
  },
  loginField: {
    marginTop: 18,
  },
  emailFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  formikStyle: {
    width: '100%',
  },
  forgotPassword: {
    fontSize: 14,
    fontWeight: 500,
  },
  loginAs: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 8,
  },
  loginContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  otpErrorMessage: {
    fontSize: '0.8rem',
    color: '#ff0000',
  },
  passwordFieldBottomMargin: {
    marginBottom: '0.6rem',
    [theme.breakpoints.up(constant.breakMobileAuthPoint)]: {
      marginBottom: '0.8rem',
    },
  },
  loginBox: {
    margin: '0 1%',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      margin: '0 1%',
    },
    [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
      margin: '0 1%',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      margin: '0 1%',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      margin: '0 1%',
    },
  },
  otpFieldStyle: {
    width: 45,
    padding: '30% 24%',
    fontSize: 14,
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      width: 45,
      padding: '30% 24%',
      fontSize: 22,
    },
    [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
      width: 45,
      padding: '30% 24%',
      fontSize: 14,
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      width: 40,
      padding: '14% 12%',
      fontSize: 17,
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      width: 35,
      padding: '30% 24%',
      fontSize: 14,
    },
  },
  loginAs: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 8,
  },
  ...constant.authButtonStyle(theme),
  ...constant.loginInputStyle(theme),
  ...constant.loginHelperTextStyle(theme),
}));

const LoginContainer = props => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    isParentReset,
    resetPassword,
    forgotPassword,
    confirmForgotPasswordOTP,
    setStatus,
    verificationMailSent,
    otpVerified,
    isPasswordReset,
  } = props;
  const [formData, setFormData] = React.useState({
    email: '',
    otp: '      ',
    password: '',
    confirmPassword: '',
    otpError: '',
  });
  const history = useHistory();
  const redirect = route => {
    history.push(route);
  };

  const SigninSchema = Yup.object().shape({
    otp1: formData.email ? Yup.string() : null,
    otp2: formData.email ? Yup.string() : null,
    otp3: formData.email ? Yup.string() : null,
    otp4: formData.email ? Yup.string() : null,
    otp5: formData.email ? Yup.string() : null,
    otp6: formData.email ? Yup.string() : null,
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string(),
    confirmPassword: Yup.string(),
  });

  const otpHandler = val => {
    let value = val.target.value;
    let length = value.length;
    let otp1 = formData.otp[0];
    let otp2 = formData.otp[1];
    let otp3 = formData.otp[2];
    let otp4 = formData.otp[3];
    let otp5 = formData.otp[4];
    let otp6 = formData.otp[5];
    if (!value) {
      if (otp6 !== ' ') {
        otp6 = ' ';
      } else if (otp5 !== ' ') {
        otp5 = ' ';
      } else if (otp4 !== ' ') {
        otp4 = ' ';
      } else if (otp3 !== ' ') {
        otp3 = ' ';
      } else if (otp2 !== ' ') {
        otp2 = ' ';
      } else if (otp1 !== ' ') {
        otp1 = ' ';
      }
    } else if (isStringInteger(value[length - 1])) {
      if (otp1 === ' ') {
        otp1 = value[length - 1];
      } else if (otp2 === ' ') {
        otp2 = value[length - 1];
      } else if (otp3 === ' ') {
        otp3 = value[length - 1];
      } else if (otp4 === ' ') {
        otp4 = value[length - 1];
      } else if (otp5 === ' ') {
        otp5 = value[length - 1];
      } else if (otp6 === ' ') {
        otp6 = value[length - 1];
      }
    }
    let text = otp1 + otp2 + otp3 + otp4 + otp5 + otp6;
    setFormData({ ...formData, otp: text });
  };

  const optFieldErrorColor = formData.otpError ? '#FBCE5A' : null;

  React.useEffect(() => {
    if (isPasswordReset) {
      setStatus({ isPasswordReset: false });
      redirect(
        isParentReset
          ? constant.routes.parentLogin
          : constant.routes.childrenLogin,
      );
    }
  }, [isPasswordReset]);

  React.useEffect(() => {
    return () => {
      setStatus({
        isParentReset: false,
        verificationMailSent: false,
        otpVerified: false,
        isPasswordReset: false,
      });
    };
  }, []);

  return (
    <Grid item xs={12}>
      <Box className={clsx(classes.selectionContainer)}>
        <Box className={clsx(classes.innerSelectionContainer)}>
          <Title
            title={
              formData.email
                ? !formData.otp.includes(' ') && otpVerified
                  ? constant.newPasswordTitle
                  : constant.verificationPasswordTitle
                : constant.resetPasswordTitle
            }
            subtitle={
              formData.email
                ? !formData.otp.includes(' ') && otpVerified
                  ? constant.newPasswordDescription
                  : constant.verificationPasswordDescription(formData.email)
                : constant.resetPasswordDescription
            }
          />
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
              email: formData.email,
              otp1: formData.otp[0] || '',
              otp2: formData.otp[1] || '',
              otp3: formData.otp[2] || '',
              otp4: formData.otp[3] || '',
              otp5: formData.otp[4] || '',
              otp6: formData.otp[5] || '',
            }}
            validationSchema={SigninSchema}
            onSubmit={values => {
              if (
                verificationMailSent &&
                otpVerified &&
                !(
                  values.confirmPassword === values.password &&
                  values.password != '' &&
                  values.password.length > 8
                )
              ) {
                setFormData({
                  ...formData,
                  otpError:
                    values.confirmPassword !== values.password
                      ? constant.passwordMatchError
                      : constant.invalidPasswordError,
                });
              } else if (
                formData?.otp?.includes(' ') &&
                formData.email &&
                verificationMailSent &&
                !otpVerified
              ) {
                setFormData({ ...formData, otpError: constant.otpError });
              } else {
                setFormData({ ...formData, otpError: '', ...values });
              }

              if (!verificationMailSent) {
                forgotPassword(values.email).then(res => {
                  console.log('Done');
                });
              } else if (!otpVerified && formData.otp != '    ') {
                confirmForgotPasswordOTP({
                  email: values.email,
                  otp: formData.otp,
                });
              } else if (
                verificationMailSent &&
                otpVerified &&
                values.confirmPassword === values.password &&
                values.password != '' &&
                values.password.length > 8
              ) {
                let token = localStorage.getItem(constant.otpToken);
                resetPassword({ token: token, password: values.password });
              }
            }}>
            {({ errors, touched }) => (
              <Form className={clsx(classes.formikStyle)}>
                {!verificationMailSent ? (
                  <Box
                    className={clsx(
                      classes.loginField,
                      classes.emailFieldContainer,
                    )}>
                    <FormikTextField
                      className={classes.inputStyle}
                      placeholder={
                        constant.email[0].toUpperCase() +
                        constant.email.slice(1)
                      }
                      name={constant.email}
                      type={constant.email}
                      touched={touched.email}
                      error={errors.email}
                      style={{
                        width: '100%',
                      }}
                    />
                  </Box>
                ) : null}
                {verificationMailSent && !otpVerified ? (
                  <Box
                    className={clsx(
                      classes.loginField,
                      classes.loginContainer,
                    )}>
                    <Box className={clsx(classes.loginBox)}>
                      <FormikTextFieldCustom
                        name={constant.otp + 1}
                        touched={touched.otp1}
                        value={formData.otp[0]}
                        onChange={otpHandler}
                        style={{
                          textAlign: 'center',
                          backgroundColor: optFieldErrorColor,
                        }}
                        className={clsx(classes.otpFieldStyle)}
                      />
                    </Box>
                    <Box className={clsx(classes.loginBox)}>
                      <FormikTextFieldCustom
                        name={constant.otp + 2}
                        touched={touched.otp2}
                        value={formData.otp[1]}
                        onChange={otpHandler}
                        style={{
                          textAlign: 'center',
                          backgroundColor: optFieldErrorColor,
                        }}
                        className={clsx(classes.otpFieldStyle)}
                      />
                    </Box>
                    <Box className={clsx(classes.loginBox)}>
                      <FormikTextFieldCustom
                        name={constant.otp + 3}
                        touched={touched.otp3}
                        value={formData.otp[2]}
                        onChange={otpHandler}
                        style={{
                          textAlign: 'center',
                          backgroundColor: optFieldErrorColor,
                        }}
                        className={clsx(classes.otpFieldStyle)}
                      />
                    </Box>
                    <Box className={clsx(classes.loginBox)}>
                      <FormikTextFieldCustom
                        name={constant.otp + 4}
                        touched={touched.otp4}
                        value={formData.otp[3]}
                        onChange={otpHandler}
                        style={{
                          textAlign: 'center',
                          backgroundColor: optFieldErrorColor,
                        }}
                        className={clsx(classes.otpFieldStyle)}
                      />
                    </Box>
                    <Box className={clsx(classes.loginBox)}>
                      <FormikTextFieldCustom
                        name={constant.otp + 5}
                        touched={touched.otp5}
                        value={formData.otp[4]}
                        onChange={otpHandler}
                        style={{
                          textAlign: 'center',
                          backgroundColor: optFieldErrorColor,
                        }}
                        className={clsx(classes.otpFieldStyle)}
                      />
                    </Box>
                    <Box className={clsx(classes.loginBox)}>
                      <FormikTextFieldCustom
                        name={constant.otp + 6}
                        touched={touched.otp6}
                        value={formData.otp[5]}
                        onChange={otpHandler}
                        style={{
                          textAlign: 'center',
                          backgroundColor: optFieldErrorColor,
                        }}
                        className={clsx(classes.otpFieldStyle)}
                      />
                    </Box>
                  </Box>
                ) : null}
                {verificationMailSent && otpVerified ? (
                  <Box
                    className={clsx(
                      classes.loginField,
                      classes.emailFieldContainer,
                    )}>
                    <FormikTextField
                      className={classes.inputStyle}
                      placeholder={
                        constant.password[0].toUpperCase() +
                        constant.password.slice(1)
                      }
                      name={constant.password}
                      type={constant.password}
                      touched={touched.password}
                      error={errors.password}
                      style={{
                        width: '100%',
                      }}
                    />
                    <FormikTextField
                      className={classes.inputStyle}
                      placeholder={constant.confirmPasswordText}
                      name={constant.confirmPassword}
                      type={constant.password}
                      touched={touched.confirmPassword}
                      error={errors.confirmPassword}
                      style={{
                        width: '100%',
                        marginTop: 18,
                      }}
                    />
                  </Box>
                ) : null}
                {formData.otpError ? (
                  <Box
                    className={clsx(
                      classes.loginField,
                      classes.loginContainer,
                    )}>
                    <Typography className={clsx(classes.otpErrorMessage)}>
                      {formData.otpError}
                    </Typography>
                  </Box>
                ) : null}
                {formData.email && verificationMailSent && !otpVerified ? (
                  <>
                    <Box className={clsx(classes.loginAs)}>
                      <BaseButton
                        onClick={() => {
                          forgotPassword(formData.email);
                        }}
                        text={
                          <Typography
                            className={clsx(classes.loginHelperTextStyle)}>
                            {constant.resendOtp}
                          </Typography>
                        }
                        style={{ fontWeight: 600 }}
                      />
                    </Box>
                  </>
                ) : null}
                <Box className={clsx(classes.loginField, classes.submitButton)}>
                  <BorderButton
                    className={classes.buttonStyle}
                    text={
                      otpVerified && !formData.otp.includes(' ')
                        ? constant.updatePassword
                        : formData.email && verificationMailSent
                        ? constant.submitOtp
                        : constant.sendOtp
                    }
                    type={constant.submit}
                    style={{ width: '100%' }}
                  />
                </Box>
              </Form>
            )}
          </Formik>
          <Box className={clsx(classes.loginAs)} style={{ width: '100%' }}>
            <BaseButton
              onClick={() =>
                redirect(
                  isParentReset
                    ? constant.routes.parentLogin
                    : constant.routes.childrenLogin,
                )
              }
              text={
                <Typography className={clsx(classes.loginHelperTextStyle)}>
                  {constant.redirectToLogin}
                </Typography>
              }
              style={{ fontWeight: 600 }}
            />
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isParentReset: authReducer.isParentReset,
    verificationMailSent: authReducer.verificationMailSent,
    otpVerified: authReducer.otpVerified,
    isPasswordReset: authReducer.isPasswordReset,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    forgotPassword: email => dispatch(actionCreator.forgotPassword(email)),
    resetPassword: data => dispatch(actionCreator.resetPassword(data)),
    confirmForgotPasswordOTP: data =>
      dispatch(actionCreator.confirmForgotPasswordOTP(data)),
    setStatus: data => dispatch(actionCreator.setStatus(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
