import React from 'react';
import {
  Container,
  Box,
  Grid,
  Link,
  useTheme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import LOGO from '../../../assets/images/logo-light.svg';
import FbIcon from '../../../assets/images/FooterImages/FacebookIcon.png';
import InstaIcon from '../../../assets/images/FooterImages/Instagramcon.png';
import TwitterIcon from '../../../assets/images/FooterImages/TwitterIcon.png';
import LinkedIcon from '../../../assets/images/FooterImages/LinkedInIcon.png';
import SettingWhite from '../../../assets/images/landingPageImages/SettingWhite.png';
import StatsWhite from '../../../assets/images/landingPageImages/StatsWhite.png';

const useStyles = makeStyles(theme => ({
  downloadIcon: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '3%',
    justifyContent: 'center',
  },
  downIcon: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 56,
  },

  alginText: {
    textAlign: 'center',
  },
  headText: {
    fontFamily: 'Inter',
    fontSize: 16,
    color: 'white',
    textAlign: 'left',
    padding: '0.5em',
  },
  subText: {
    fontFamily: 'Open Sans',
    fontSize: 16,
    textAlign: 'center',
    paddingLeft: '0rem',
  },
  paddingDown: {
    paddingBottom: 40,
    fontFamily: 'Open Sans',
  },
  socialMediaContainer: {
    //   padding: "1rem",
    //   display: "flex",
    //   flexDirection: "column",
    //   justifyContent: "space-between",
    //   [theme.breakpoints.down(450)]: {
    //    padding: 0,
    //   },
  },
  socialIcons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    textAlign: 'left',
    margin: 'auto 2.5rem',
    [theme.breakpoints.down(1280)]: { margin: 'auto 10rem' },
    [theme.breakpoints.down(960)]: { margin: 'auto 7.5rem' },
    [theme.breakpoints.down(800)]: { margin: 'auto 5rem' },
    [theme.breakpoints.down(650)]: { margin: 'auto 3rem' },
    [theme.breakpoints.down(450)]: { margin: 'auto 2.5rem' },
  },
}));

const Footer = () => {
  const themed = useTheme();
  const matches = useMediaQuery(themed.breakpoints.down('md'));
  const matchesSm = useMediaQuery(themed.breakpoints.down('sm'));

  const classes = useStyles();
  return (
    <Box
      bgcolor="#155B6B"
      className="footerContainer"
      style={{ overflow: 'clip' }}>
      <img
        src={StatsWhite}
        align="right"
        style={{ paddingTop: matches ? '50px' : '80px', width: '50px' }}
      />
      <img
        src={SettingWhite}
        align="left"
        style={{ paddingBottom: matches ? '0px' : '150px', width: '50px' }}
      />
      <Box
        bgcolor="#155B6B"
        className="footerContainer"
        style={{
          overflow: 'clip',
          padding: matches ? '0px 0px 0px 0px' : '50px 80px 10px 90px',
        }}>
        {matches ? (
          <>
            <Grid container>
              <Box>
                <img
                  src={LOGO}
                  style={{
                    width: '100%',
                    paddingLeft: '50px',
                  }}
                />
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    color: 'white',
                    letterSpacing: '0.5px',
                    paddingLeft: '50px',
                  }}>
                  Rewarding Responsibilities
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              style={{
                padding: '2rem 0 0 0rem ',
                display: 'flex',
                color: 'white',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                }}>
                <Box>
                  <Box className={classes.headText}>
                    <Link href="/careers" color="inherit">
                      Careers Page
                    </Link>
                  </Box>
                  <Box className={classes.headText}>
                    <Link href="/" color="inherit">
                      {/* {"Help & Support"} */}
                    </Link>
                  </Box>
                  <Box className={classes.headText}>
                    <Link href="/" color="inherit">
                      {/* FAQs */}
                    </Link>
                  </Box>
                </Box>
                <Box>
                  <Box className={classes.headText}>
                    <Link href="/contactUs" color="inherit">
                      Contact Us
                    </Link>
                  </Box>
                  <Box className={classes.headText}>
                    <Link href="/" color="inherit">
                      {/* {"Blog & News"} */}
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  style={{
                    textAlign: 'center',
                  }}>
                  <hr
                    style={{
                      marginTop: '50px',
                      background: 'white',
                      color: 'white',
                      borderColor: 'white',
                      borderWidth: '0px',
                      height: '1px',
                    }}
                  />
                  <Box>
                    <Grid xs={12} className={classes.subText}>
                      <Typography
                        style={{
                          fontFamily: 'Inter',
                          fontWeight: '400',
                          color: '#ffff',
                        }}>
                        {'All the rights reserved to COGITO 2023 Ltd. |'}
                        <Link href="/privacy" color="inherit">
                          Privacy Policy
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid xs={12}>
                      <Box
                        style={{
                          alignItems: 'center',
                        }}>
                        <Link
                          target="_blank"
                          href="https://www.facebook.com/cogitometaverse/">
                          <img
                            src={FbIcon}
                            style={{
                              width: '30px',
                              paddingRight: '0.2rem',
                            }}
                          />
                        </Link>
                        <Link
                          target="_blank"
                          href="https://www.instagram.com/cogito/">
                          <img
                            src={InstaIcon}
                            style={{
                              width: '30px',
                              paddingRight: '0.2rem',
                            }}
                          />
                        </Link>
                        <Link
                          target="_blank"
                          href="https://twitter.com/CogitoMetaverse">
                          <img
                            src={TwitterIcon}
                            style={{
                              width: '30px',
                              paddingRight: '0.2rem',
                            }}
                          />
                        </Link>
                        <Link
                          target="_blank"
                          href="https://www.linkedin.com/company/principality-cogito/">
                          <img
                            src={LinkedIcon}
                            style={{
                              width: '30px',
                              //  paddingLeft: "1.5%",
                            }}
                          />
                        </Link>
                      </Box>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              style={{
                padding: '0rem 3rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              <Box>
                <img
                  src={LOGO}
                  alt="LOGO"
                  style={{
                    width: '50px',
                    color: '#359DB6',
                    alignItem: 'left',
                  }}
                />
                <Typography
                  style={{
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    letterSpacing: '0.5px',
                    color: 'white',
                  }}>
                  Rewarding Responsibilities
                </Typography>
              </Box>
              <Box>
                <Box className={classes.headText}>
                  <Link href="/careers" color="inherit">
                    Careers Page
                  </Link>
                </Box>
                <Box className={classes.headText}>
                  <Link href="/" color="inherit">
                    {/* {"Help & Support"} */}
                  </Link>
                </Box>
                <Box>
                  <Box className={classes.headText}>
                    <Link href="/" color="inherit">
                      {/* FAQs */}
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box className={classes.headText} py={{ sm: 1 }}>
                  <Link href="/contactUs" color="inherit">
                    Contact Us
                  </Link>
                </Box>
                <Box className={classes.headText} py={{ sm: 1 }}>
                  <Link href="/" color="inherit">
                    {/* {"Blog & News"} */}
                  </Link>
                </Box>
              </Box>
              <Box></Box>
            </Grid>
            <Grid>
              <hr
                style={{
                  marginTop: '50px',
                  background: 'white',
                  color: 'white',
                  borderColor: 'white',
                  borderWidth: '0px',
                  height: '1px',
                }}
              />
              <Box>
                <Grid xs={12} container>
                  <Grid xs={11} className={classes.subText}>
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontWeight: '400',
                        color: '#ffff',
                      }}>
                      {'All the rights reserved to COGITO 2023 Ltd. |'}{' '}
                      <Link href="/privacy" color="inherit">
                        Privacy Policy
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid
                    xs={1}
                    style={{
                      alignItems: 'right',
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'right',
                      }}>
                      <Link
                        target="_blank"
                        href="https://www.facebook.com/cogitometaverse/">
                        <img
                          src={FbIcon}
                          style={{
                            width: '30px',
                            paddingRight: '0.2rem',
                          }}
                        />
                      </Link>
                      <Link
                        target="_blank"
                        href="https://www.instagram.com/cogito/">
                        <img
                          src={InstaIcon}
                          style={{
                            width: '30px',
                            paddingRight: '0.2rem',
                          }}
                        />
                      </Link>
                      <Link
                        target="_blank"
                        href="https://twitter.com/CogitoMetaverse">
                        <img
                          src={TwitterIcon}
                          style={{
                            width: '30px',
                            paddingRight: '0.2rem',
                          }}
                        />
                      </Link>
                      <Link
                        target="_blank"
                        href="https://www.linkedin.com/company/principality-cogito/">
                        <img
                          src={LinkedIcon}
                          style={{
                            width: '30px',
                            //  paddingLeft: "1.5%",
                          }}
                        />
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Footer;
