import { makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { Field } from 'formik';

const useStyles = makeStyles(theme => ({
  inputStyle: {
    border: 'none',
    padding: '14px',
    borderRadius: 8,
    backgroundColor: '#359DB640',
    width: 121,
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'poppins',
  },
}));

const FormikTextField = props => {
  const {
    name,
    type,
    touched,
    error,
    border,
    padding,
    borderRadius,
    backgroundColor,
    width,
    fontFamily,
    fontSize,
    fontWeight,
    style,
    textStyle,
    placeholder,
    className,
    id,
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <>
      <Field
        name={name}
        type={type}
        placeholder={placeholder || ''}
        className={clsx(
          'no-outline login-input',
          classes.inputStyle,
          className,
        )}
        id={id}
        style={{
          border,
          padding,
          borderRadius,
          backgroundColor,
          width,
          fontFamily,
          fontSize,
          fontWeight,
          ...style,
        }}
      />
      {error && touched ? (
        <div
          style={{
            fontFamily,
            fontSize,
            fontWeight,
            color: 'red',
            ...textStyle,
          }}>
          {error}
        </div>
      ) : null}
    </>
  );
};
FormikTextField.defaultProps = {
  name: '',
  type: 'text',
  style: {},
  textStyle: {},
  className: '',
};

export default FormikTextField;
