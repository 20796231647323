import { Box, makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { connect } from 'react-redux';
import * as actionCreator from '../../../store/action';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { constant } from '../../../utils/constant';
import { maxStringLength } from '../../../utils/stringMethods';
import ContainerTitle from '../../../components/common/title/ContainerTitle';
import {
  getAmPm,
  getDateMonthYearFormat,
  getTime12HFormat,
  isTaskActive,
} from '../../../utils/timeMethods';
import React from 'react';
import CommonModel from '../../../components/common/model/CommonModel';
import ProfilePic from '../../../components/profilePic/ProfilePic';
import BorderButton from '../../../components/buttons/BorderButton';
import UpdateModal from '../../../components/tasks/UpdateModal';
import EditTaskModal from './EditTaskModal';

const useStyle = makeStyles(theme => ({
  created: {
    backgroundColor: '#C9E5EC',
  },
  inProgress: {
    backgroundColor: '#C9E5EC',
  },
  // scheduled: {
  //   backgroundColor: '#C9E5EC',
  // },
  childApproved: {
    backgroundColor: '#E3D8D5',
  },
  parentApproved: {
    backgroundColor: '#C5E2E2',
  },
  overDue: {
    backgroundColor: '#C9ADA7',
  },
  container: {
    padding: '0.3rem 0.6rem',
    borderRadius: 8,
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  childContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  directionColumn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  profileIconStyle: {
    fontSize: '2.8rem',
    height: '2.8rem',
    marginRight: '0.5rem',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '2.5rem',
      height: '2.5rem',
      marginRight: '0.7rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '1.8rem',
      height: '1.8rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '1.6rem',
      height: '1.6rem',
    },
  },
  unCheckedColor: {
    color: '#359DB6',
  },
  checkedColor: {
    color: '#C49C39',
  },
  checkedIconStyle: {
    color: '#699595',
    fontSize: '1.4rem',
    marginRight: '0.5rem',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '1.9rem',
      marginRight: '0.7rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '1.5rem',
      marginRight: '0.4rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '1.1rem',
      marginRight: '0.3rem',
    },
  },
  nameStyle: {
    fontSize: '0.9rem !important',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.8rem !important',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.7rem !important',
    },
  },
  divideDescription: {
    margin: '0 0.5rem',
  },
  taskNameStyle: {
    // color: "#6F6F6F !important",
    fontSize: '0.6rem !important',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.5rem !important',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.5rem !important',
    },
  },
  timeStyle: {
    fontSize: '0.6rem',
    fontWeight: 550,
    // color: "#333333",
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.5rem !important',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.5rem !important',
    },
  },
  amPmStyle: {
    fontSize: '0.6rem',
    fontWeight: 500,
    // color: "#999999",
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.5rem !important',
      fontWeight: 'bold',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.5rem !important',
      fontWeight: 'bold',
    },
  },
  calendarStyle: {
    fontSize: '1rem',
    // color: "#6F6F6F",
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.9rem !important',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.7rem !important',
    },
  },
  dateStyle: {
    fontSize: '0.7rem',
    // color: '#6F6F6F',
    marginRight: '0.2rem',
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.6rem !important',
      marginLeft: '0.2rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '0.5rem !important',
      marginLeft: '0.1rem',
    },
  },
  modelTitleContainerStyle: {
    justifyContent: 'flex-start !important',
  },
  taskColorDark: {
    color: '#333333',
  },
  taskColorGreyDark: {
    color: '#699595',
  },
  taskColorLight: {
    color: '#303030',
  },
  taskColorGreyLight: {
    color: '#DCDCDC',
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column',
    // alignItems: "center",
  },

  approveButtonStyle: {
    color: '#b59c96',
    border: '1.8px solid #b59c96',
    backgroundColor: 'transparent',
    fontSize: '0.6rem',
    fontWeight: 400,
    height: 25,
    padding: 0,
    width: 80,
    '&:hover': {
      backgroundColor: '#b59c96',
      color: '#fff',
    },
    borderRadius: 3,
  },
  taskStatusStyle: {
    fontSize: 12,
    fontWeight: 600,
    // display: "flex",
    // alignItems: "inherit",
  },
  completedStatusColor: {
    color: '#303030',
  },
  childSubmitStatusColor: {
    color: '#FFDECC',
  },
  onGoingTaskColor: {
    color: '#083750',
  },
  scheduledTaskColor: {
    color: '#083750',
  },
  titleTextStyle: {
    color: theme.customTheme.commonModalTextStyle,
  },
  taskNameContainer: {
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: '1rem',
  },
}));
const TaskContainer = props => {
  const { checked, childList, taskDetail, isParentLoggedIn,index } = props;
  const theme = useTheme();
  const classes = useStyle(theme);
  const [child, setChild] = React.useState(null);
  const [updateModal, setUpdateModal] = React.useState(null);
  const [editModal, setEditModal] = React.useState(null);

  const handleUpdateTaskModal = () => {
    // if (!checked && taskDetail.status == constant.childApprovedStatus) {
    setUpdateModal(!updateModal);
    // }
  };

  const handleEditTaskModal = () => {
    setEditModal(!editModal);
  };

  const isApproved =
    taskDetail.status === constant.parentApprovedStatus ||
    taskDetail.status === constant.overdueStatus;
  const normalColor = isApproved
    ? classes.taskColorLight
    : classes.taskColorDark;
  const greyColor = isApproved
    ? classes.taskColorLight
    : classes.taskColorGreyDark;

  const taskStatus =
    taskDetail.status == constant.parentApprovedStatus
      ? 'Completed'
      : taskDetail.status == constant.childApproved
      ? 'Submitted'
      : taskDetail.status == constant.createdStatus
      ? isTaskActive(taskDetail.taskTimeStamp)
        ? 'Ongoing'
        : 'Scheduled'
      : '';

  const taskStatusColor =
    taskDetail.status == constant.parentApprovedStatus
      ? classes.completedStatusColor
      : taskDetail.status == constant.childApprovedStatus
      ? classes.childSubmitStatusColor
      : taskDetail.status == constant.createdStatus
      ? isTaskActive(taskDetail.taskTimeStamp)
        ? classes.onGoingTaskColor
        : classes.scheduledTaskColor
      : '';

  React.useEffect(() => {
    childList?.map(childData => {
      if (childData?._id == taskDetail?.childId) {
        setChild(childData);
      }
    });
    console.log(taskDetail)
  }, [childList, taskDetail]);
  return (
    <Box
      className={clsx(
        classes.container,
        taskDetail.status === constant.createdStatus
          ? isTaskActive(taskDetail.taskTimeStamp)
            ? classes.inProgress
            : classes.created
          : taskDetail.status === constant.childApprovedStatus
          ? classes.childApproved
          : taskDetail.status === constant.parentApprovedStatus
          ? classes.parentApproved
          : taskDetail.status === constant.overdueStatus
          ? classes.overDue
          : '',
      )}>
      <CommonModel
        title={
          taskDetail.status === constant.childApprovedStatus
            ? constant.updateTask
            : 'Task Detail'
        }
        titleContainerStyle={classes.modelTitleContainerStyle}
        titleStyle={classes.titleTextStyle}
        open={updateModal}
        handleClose={handleUpdateTaskModal}
        
        body={
          <UpdateModal
            open={updateModal}
            id='update-modal'
            handleClose={handleUpdateTaskModal}
            handleEdit={handleEditTaskModal}
            taskDetail={{ ...taskDetail, child }}
            isParentLoggedIn={isParentLoggedIn}
          />
        }
      />
      <CommonModel
        title={'Edit task'}
        titleContainerStyle={classes.modelTitleContainerStyle}
        titleStyle={classes.titleTextStyle}
        open={editModal}
        handleClose={handleEditTaskModal}
        body={
          <EditTaskModal
            open={editModal}
            handleClose={handleEditTaskModal}
            taskDetail={{ ...taskDetail, child }}
            isParentLoggedIn={isParentLoggedIn}
          />
        }
      />
      <Box
        className={clsx(classes.innerContainer)}
        onClick={handleUpdateTaskModal} id={`task-${taskDetail?.id}`}>
        <Box className={clsx(classes.childContainer)}>
          {/* {isApproved ? (
            <CheckBoxOutlined className={clsx(classes.checkedIconStyle)} />
          ) : (
            <CheckBoxOutlineBlank
              className={clsx(classes.checkedIconStyle, classes.unCheckedColor)}
            />
          )} */}
          {child?.profile_pic ? (
            <ProfilePic
              pic={`${process.env.REACT_APP_SPACE_KEY}/${child?.profile_pic}`}
            />
          ) : (
            <AccountCircleIcon
              className={clsx(classes.profileIconStyle, normalColor)}
            />
          )}
          <Box className={clsx(classes.directionColumn)}>
            <Box>
              <ContainerTitle
                title={(child?.firstName || '') + ' ' + (child?.lastName || '')}
                className={clsx(classes.nameStyle, normalColor)}
              />
            </Box>
            <Box
              className={clsx(
                classes.childContainer,
                classes.taskNameContainer,
              )}>
              <ContainerTitle
                title={maxStringLength(taskDetail?.taskTitle || '', 16) + ' | '}
                className={clsx(classes.taskNameStyle, normalColor)}
              />
              &nbsp;
              <Box className={clsx(classes.childContainer)}>
                <span
                  className={clsx(classes.timeStyle, classes.taskColorLight)}>
                  {taskDetail?.taskTimeStamp
                    ? getTime12HFormat(taskDetail.taskTimeStamp)
                    : null}
                </span>
                <span
                  className={clsx(classes.amPmStyle, classes.taskColorLight)}>
                  {taskDetail?.taskTimeStamp
                    ? getAmPm(taskDetail.taskTimeStamp)
                    : null}
                  {'-'}
                </span>
                <span
                  className={clsx(classes.timeStyle, classes.taskColorLight)}>
                  {taskDetail.deadline
                    ? getTime12HFormat(taskDetail.deadline)
                    : null}
                </span>
                <span
                  className={clsx(classes.amPmStyle, classes.taskColorLight)}>
                  {taskDetail.deadline ? getAmPm(taskDetail.deadline) : null}
                </span>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={clsx(classes.rightContainer)}>
          {taskDetail.status === constant.childApprovedStatus ? (
            <BorderButton
              className={classes.approveButtonStyle}
              text={'Approve Now'}
              onClick={handleUpdateTaskModal}
            />
          ) : (
            <ContainerTitle
              title={taskStatus}
              className={clsx(classes.taskStatusStyle, taskStatusColor)}
            />
          )}
          <Box className={clsx(classes.childContainer)}>
            {/* <DateRangeIcon className={clsx(classes.calendarStyle, greyColor)} /> */}
            <span className={clsx(classes.dateStyle, classes.taskColorLight)}>
              {taskDetail.taskTimeStamp
                ? getDateMonthYearFormat(taskDetail.taskTimeStamp)
                : null}
            </span>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return {
    childList: userReducer.childList,
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(TaskContainer);
