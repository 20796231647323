import {
  Box,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import BaseButton from '../../../../components/buttons/BaseButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { constant } from '../../../../utils/constant';
import { Link, useHistory } from 'react-router-dom';
import themeImage1 from '../../../../assets/images/loginPage/LoginPageThemeImage1.png';
import themeImage2 from '../../../../assets/images/loginPage/LoginPageThemeImage2.png';
import CogitoLogo from '../../../../components/common/Logo/CogitoLogo';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    // height: '100vh',
    position: 'relative',
    minHeight: '100vh',
  },
  themeImage1: theme?.commonTheme?.authThemeImage1,
  themeImage1Style: theme?.commonTheme?.authThemeImage1Style,
  themeImage2: theme?.commonTheme?.authThemeImage2,
  themeImage2Style: theme?.commonTheme?.authThemeImage2Style,
  innerContainer: {
    zIndex: 3,
    position: 'relative',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      padding: '50px 70px',
    },
    [theme.breakpoints.down(constant.breakLaptopAuthPoint)]: {
      padding: '50px 70px',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      padding: '20px 25px',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      padding: '13px 15px',
    },
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
  },
  selectionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 30,
    marginTop: 40,
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      paddingLeft: 0,
      justifyContent: 'center',
      marginTop: 10,
    },
  },
  backtextStyle: {
    fontSize: '1.2rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '0.8rem',
    },
  },
  backIconStyle: {
    fontSize: '1.8rem',
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '1.1rem',
    },
  },
  logoStyle: {
    width: '4rem',
    [theme.breakpoints.up('lg')]: {
      width: '4rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '2.5rem',
    },
  },
}));

const LeftContainer = props => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { child, backButton, isParent } = props;
  const history = useHistory();
  const redirect = route => {
    if (route !== true) {
      history.push(route);
    } else {
      history.goBack();
    }
  };
  const smallMobile = useMediaQuery(
    theme.breakpoints.down(constant.breakSmallMobileAuthPoint),
  );
  const mobile = useMediaQuery(
    theme.breakpoints.down(constant.breakMobileAuthPoint),
  );
  const spacing = smallMobile ? 1 : mobile ? 2 : 3;
  return (
    <Box className={clsx(classes.container)}>
      <Grid className={clsx(classes.innerContainer)} container>
        <Grid container item xs={12} spacing={spacing}>
          <Grid item xs={12}>
            <Typography
              style={{ fontSize: 28, fontWeight: 600, color: '#359DB6' }}>
              <Link to="/">
                <CogitoLogo className={classes.logoStyle} />
              </Link>
            </Typography>
          </Grid>
          {/* {backButton ? ( */}
          <Grid item xs={12}>
            <BaseButton
              text={
                <Typography className={clsx(classes.backButton)}>
                  <ChevronLeftIcon className={clsx(classes.backIconStyle)} />
                  <Typography className={clsx(classes.backtextStyle)}>
                    {constant.back}
                  </Typography>
                </Typography>
              }
              // onClick={() => redirect(backButton)}
              onClick={() => history.goBack()}
            />
          </Grid>
          {/* ) : null} */}
        </Grid>
        {child}
      </Grid>
      {isParent === false ? (
        <>
          <Box className={clsx(classes.themeImage1)}>
            <img src={themeImage1} className={clsx(classes.themeImage1Style)} />
          </Box>
          <Box className={clsx(classes.themeImage2)}>
            <img src={themeImage2} className={clsx(classes.themeImage2Style)} />
          </Box>
        </>
      ) : null}
    </Box>
  );
};

const mapStateToProps = ({}) => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(LeftContainer);
