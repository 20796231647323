import AccountType from '../pages/preLoggedIn/accountType/AccountType';
import ChildrenLogin from '../pages/loginPage/ChildrenLogin';
import ParentLogin from '../pages/loginPage/ParentLogin';
import { constant } from '../utils/constant';
import LandingPage from '../pages/preLoggedIn/landing/LandingPage';
import ContactUsPage from '../pages/preLoggedIn/contactUs/ContactUsPage';
import PasswordReset from '../pages/loginPage/PasswordReset';
import CareersPage from '../pages/preLoggedIn/Careers/careersPage';
import AboutUsPage from '../pages/preLoggedIn/aboutUs/AboutUsPage';
import PrivacyPolicy from '../pages/preLoggedIn/privacyPolicy/privacyPolicy';
import RedirectInfoPage from '../pages/preLoggedIn/redirectInfo/RedirectInfoPage';
import OurStory from '../pages/preLoggedIn/aboutUs/OurStory/OurStory';
// import CalenderPage from '../pages/calenderPage/CalenderPage';

export const authRoutes = {
  routes: [
    {
      path: constant.routes.landingPage,
      component: LandingPage,
      isExact: true,
    },
    {
      path: constant.routes.accountType,
      component: AccountType,
    },
    { path: constant.routes.parentLogin, component: ParentLogin },
    { path: constant.routes.childrenLogin, component: ChildrenLogin },
    { path: constant.routes.resetPassword, component: PasswordReset },
    {
      path: constant.routes.aboutUs,
      component: AboutUsPage,
    },
    {
      path: constant.routes.ourStory,
      component: OurStory,
    },
    {
      path: constant.routes.privacyPolicy,
      component: PrivacyPolicy,
    },
    {
      path: constant.routes.contactUs,
      component: ContactUsPage,
    },
    // {
    //   path: constant.routes.careers,
    //   component: CareersPage,
    //   isExact: true,
    // },
    {
      path: constant.routes.redirectInfo,
      component: RedirectInfoPage,
    },
  ],
  redirect: constant.routes.accountType,
};
