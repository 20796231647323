import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import ContainerTitle from '../../../components/common/title/ContainerTitle';
import BorderButton from '../../../components/buttons/BorderButton';
import * as actionCreator from '../../../store/action';
import React from 'react';
import { connect } from 'react-redux';

const useStyle = makeStyles(theme => ({
  createTaskContainer: {
    width: '100%',
    maxWidth: '800px',
  },
  desclaimerStyle: {
    color: '#333333',
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 24,
  },
  cancelClass: {
    marginRight: 16,
    color: '#359DB6',
    border: '2px solid #359DB6',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  disclaimerText: {
    fontSize: '0.7rem',
  },
}));

const DismissKyc = props => {
  const { open, handleClose, notify, profile, removeRelation, approveKyc } =
    props;
  const theme = useTheme();
  const classes = useStyle(theme);

  const handleDismissKyc = () => {
    let data = {
      id: open._id,
      status: false,
    };
    approveKyc(data);
    handleClose();
  };
  return (
    <>
      <Box
        className={clsx(
          classes.createTaskContainer,
          'avoid-autofill-color-359DB6',
        )}>
        <Box textAlign={'center'}>
          <ContainerTitle
            title={`Are you sure you want to Dismiss ${open.firstName}'s KYC?`}
            className={clsx(classes.desclaimerStyle)}
          />
        </Box>
        <Box textAlign={'center'}>
          <Typography className={classes.disclaimerText}>
            *If you dismiss your kids KYC ,they will no longer be able to access
            banking section in Cogito Metaverse.
          </Typography>
        </Box>
        <Box className={clsx(classes.actionContainer)}>
          <BorderButton
            text={'Cancel'}
            onClick={handleClose}
            className={classes.cancelClass}
          />
          <BorderButton text={'Confirm'} onClick={handleDismissKyc} />
        </Box>
      </Box>
    </>
  );
};
const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    recommendationLoader: userReducer?.loadings?.getRecommendation,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    notify: (data, variant) => {
      dispatch(actionCreator.notify(data, variant));
    },
    getRecommendationLoader: status => {
      dispatch(actionCreator.setGetRecommendation(status));
    },
    updateChildData: data => actionCreator.updateChildData(data),
    uploadDoc: data => actionCreator.uploadDoc(data),
    getChildList: () => {
      dispatch(actionCreator.getChildList());
    },
    removeRelation: data => {
      dispatch(actionCreator.removeRelation(data));
    },
    approveKyc: data => {
      dispatch(actionCreator.approveKyc(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DismissKyc);
