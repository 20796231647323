import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import ContainerTitle from '../../../components/common/title/ContainerTitle';
import BorderButton from '../../../components/buttons/BorderButton';
import * as actionCreator from '../../../store/action';
import {
  getDeadline,
  getPreviousDate,
  getTimeFromSeconds,
  getTimeInMinutes,
  mergeDateAndTime,
  yyyyMmDd,
} from '../../../utils/timeMethods';
import CustomTextField from '../../../components/muiInputFields.js/CustomTextField';
import React from 'react';
import { connect } from 'react-redux';
import { constant } from '../../../utils/constant';
import CustomSelectField from '../../../components/muiInputFields.js/CustomSelectFiled';
import { VerifiedUserRounded, ViewStream } from '@material-ui/icons';
import BaseButton from '../../../components/buttons/BaseButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { isStringInteger, maxStringLength } from '../../../utils/stringMethods';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getLocalData, setLocalData } from '../../../assets/utility';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FullScreenLoader from '../../../components/loader/FullScreenLoader';

const useStyle = makeStyles(theme => ({
  heading: {
    color: '#17505E',
    fontWeight: 'bold',
    marginTop: '2%',
  },
  createTaskContainer: {
    width: '100%',
    maxWidth: '800px',
  },
  fieldContainer: {
    position: 'relative',
    background: '#A3CED9',
    borderRadius: '10px',
    padding: '5px 15px',
  },
  titleStyle: {
    color: '#333333',
    fontSize: '1rem',
    fontWeight: 550,
  },
  assignToList: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: 12,
  },
  chipStyle: {
    margin: '0 5px',
    padding: 4,
    border: '1.3px solid #074D5F',
    minWidth: 100,
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
      border: '1.3px solid #888',
    },
  },
  unSelectedChipStyle: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectedChipStyle: {
    backgroundColor: '#A3D4E0',
    '&:hover': {
      backgroundColor: '#A3D4E0',
    },
    '&:focus': {
      backgroundColor: '#A3D4E0',
    },
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12,
  },
  errorStyle: {
    color: 'red',
    fontSize: '0.9rem',
    fontWeight: 500,
    position: 'absolute',
    bottom: -25,
  },
  selectedDateContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  inputFieldStyle: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: -10000,
  },
  uploadChildProfileImageStyle: {
    width: '100%',
    backgroundColor: 'transparent',
    color: '#333',
    // border: "2px solid #359DB6",
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8%',
    [theme.breakpoints.down('sm')]: {
      padding: '6%',
    },
  },
  imageStyle: {
    width: '50px',
    maxWidth: 500,
    marginRight: '1rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  addCircleSharpIcon: {
    fontSize: '1.3rem',
    color: '#333',
    marginLeft: 5,
  },
  addGuardianContainerStyle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  guardianListContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  guardianAddedContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1.5px solid #359DB6',
    borderRadius: 18,
    padding: 2,
  },
  guardianInnerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  profileIconStyle: {
    fontSize: '1.5rem',
    marginRight: '0.5rem',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '2.5rem',
      marginRight: '0.7rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '1.6rem',
    },
  },
  backContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backIconStyle: {
    fontSize: '1rem',
  },
  backTextStyle: {
    fontSize: '1rem',
  },
  verifyTitleContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  verifyTitleTextStyle: {
    color: '#155B6B',
  },
  verifyTitleDescriptionContainerStyle: {
    color: '#155B6B',
    display: 'flex',
    justifyContent: 'center',
  },
  verifyTitleDescriptionTextStyle: {
    textAlign: 'center',
    color: '#155B6B',
    fontSize: '0.8rem',
    maxWidth: 422,
  },
  otpInputBoxContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  otpTextFieldStyle: {
    width: '2.5rem',
    margin: '0 3px',
    backgroundColor: '#359DB6',
    borderRadius: 5,
    textAlign: 'center',
  },
  stage3InputField: {
    backgroundColor: '#359DB6',
  },
  stage3InputWidth: {
    width: '60%',
  },
  centerField: {
    display: 'flex',
    justifyContent: 'center',
  },
  closeIconStyle: {
    color: '#359DB6',
  },
}));

const CreateChildModal = props => {
  const {
    open,
    handleClose,
    notify,
    getRecommendationLoader,
    recommendationLoader,
    sendOtp,
    confirmOtp,
    createChildrenAccount,
    profile,
    getChildrenRequest,
    uploadDoc,
    addChildRequest,
    childList,
    sendOtpLoader,
    getProfile,
  } = props;
  const theme = useTheme();
  const classes = useStyle(theme);

  const [stage, setStage] = React.useState(0);

  const [value, setValue] = React.useState('preRegisteredUser');

  const handleChange = event => {
    setValue(event.target.value);
  };

  const otpHandler = e => {
    let input = e.target.value;
    let value = (input && input[input?.length - 1]) || '';
    if (isStringInteger(value)) {
      setFieldData(data => {
        let tempData = {};
        tempData.otp0 = data.otp0;
        tempData.otp1 = data.otp1;
        tempData.otp2 = data.otp2;
        tempData.otp3 = data.otp3;
        tempData.otp4 = data.otp4;
        tempData.otp5 = data.otp5;

        if (!tempData.otp0.value) {
          tempData.otp0.value = value;
        } else if (!tempData.otp1.value) {
          tempData.otp1.value = value;
        } else if (!tempData.otp2.value) {
          tempData.otp2.value = value;
        } else if (!tempData.otp3.value) {
          tempData.otp3.value = value;
        } else if (!tempData.otp4.value) {
          tempData.otp4.value = value;
        } else if (!tempData.otp5.value) {
          tempData.otp5.value = value;
        }
        return { ...data, ...tempData };
      });
    } else if (input === '') {
      setFieldData(data => {
        let tempData = {};
        tempData.otp0 = data.otp0;
        tempData.otp1 = data.otp1;
        tempData.otp2 = data.otp2;
        tempData.otp3 = data.otp3;
        tempData.otp4 = data.otp4;
        tempData.otp5 = data.otp5;

        if (tempData.otp5.value) {
          tempData.otp5.value = '';
        } else if (tempData.otp4.value) {
          tempData.otp4.value = '';
        } else if (tempData.otp3.value) {
          tempData.otp3.value = '';
        } else if (tempData.otp2.value) {
          tempData.otp2.value = '';
        } else if (tempData.otp1.value) {
          tempData.otp1.value = '';
        } else if (tempData.otp0.value) {
          tempData.otp0.value = '';
        }
        return { ...data, ...tempData };
      });
    }
  };

  const [timer, setTimer] = React.useState(0);
  const [childAcc, setChildAcc] = React.useState(null);
  let defaultFieldState = {
    firstName: {
      value: '',
      errorMessage: '',
      required: 'true',
      onChange: e => {
        onChangeTextfieldHandler('firstName', e);
      },
    },
    lastName: {
      value: '',
      errorMessage: '',
      required: 'true',
      onChange: e => {
        onChangeTextfieldHandler('lastName', e);
      },
    },
    email: {
      value: '',
      errorMessage: '',
      type: 'email',
      required: 'true',
      onChange: e => {
        onChangeTextfieldHandler('email', e);
      },
    },
    dob: {
      value: '',
      errorMessage: '',
      type: 'date',
      inputProps: {
        min: yyyyMmDd(getPreviousDate(16)),
        max: yyyyMmDd(new Date()),
      },
      minDate: '2022-10-29',
      maxDate: yyyyMmDd(new Date()),
      required: 'true',
      onChange: e => {
        onChangeTextfieldHandler('dob', e);
      },
    },
    relation: {
      value: '',
      options: [
        { value: 'father', label: 'Father' },
        { value: 'mother', label: 'Mother' },
        { value: 'guardian', label: 'Guardian' },
      ],
      errorMessage: '',
      required: 'true',
      onChange: e => {
        onChangeTextfieldHandler('relation', e);
      },
    },
    role: {
      value: '',
      options: [
        { value: 'son', label: 'Son' },
        { value: 'daughter', label: 'Daughter' },
        { value: 'ward', label: 'Ward' },
      ],
      errorMessage: '',
      required: 'true',
      onChange: e => {
        onChangeTextfieldHandler('role', e);
      },
    },
    childName: {
      value: '',
      errorMessage: '',
      isValidUser: false,
      onChange: e => {
        onChangeTextfieldHandler('childName', e);
        onChangeTextfieldErrorHandler('childName', '');
      },
    },
    guardianName: {
      value: '',
      errorMessage: '',
      isValidUser: false,
      onChange: e => {
        onChangeTextfieldHandler('guardianName', e);
      },
    },
    guardianRelation: {
      value: '',
      options: [
        { value: 'father', label: 'Father' },
        { value: 'mother', label: 'Mother' },
        { value: 'guardian', label: 'Guardian' },
      ],
      errorMessage: '',
      onChange: e => {
        onChangeTextfieldHandler('guardianRelation', e);
      },
    },
    guardianList: {
      value: [],
      errorMessage: '',
    },
    profileImage: {
      value: '',
      errorMessage: '',
    },
    fileUpload: {
      file: {},
      type: 'file',
      id: 'child-profile-upload-id',
      accept: '.jpeg,.jpg,.png',
    },
    otp0: {
      value: '',
      variant: 'outlined',
      onChange: otpHandler,
    },
    otp1: {
      value: '',
      variant: 'outlined',
      onChange: otpHandler,
    },
    otp2: {
      value: '',
      variant: 'outlined',
      onChange: otpHandler,
    },
    otp3: {
      value: '',
      variant: 'outlined',
      onChange: otpHandler,
    },
    otp4: {
      value: '',
      variant: 'outlined',
      onChange: otpHandler,
    },
    otp5: {
      value: '',
      variant: 'outlined',
      onChange: otpHandler,
    },
    userName: {
      value: '',
      errorMessage: '',
      required: 'true',
      variant: 'outlined',
      label: 'User Name',
      onChange: e => {
        onChangeTextfieldHandler('userName', e);
      },
    },
    password: {
      value: '',
      errorMessage: '',
      required: 'true',
      variant: 'outlined',
      label: 'Password',
      onChange: e => {
        onChangeTextfieldHandler('password', e);
      },
    },
    confirmPassword: {
      value: '',
      errorMessage: '',
      required: 'true',
      variant: 'outlined',
      label: 'Confirm Password',
      onChange: e => {
        onChangeTextfieldHandler('confirmPassword', e);
      },
    },
    passwordType: {
      value: true,
    },
  };
  const [fieldData, setFieldData] = React.useState(defaultFieldState);

  const imageCaptureMethod = e => {
    if (e.target?.files?.[0]?.size <= 5242880) {
      let keyName = e.target.name;
      let imageUrl = URL.createObjectURL(e.target.files[0]);
      let file = e.target.files[0];
      setFieldData(data => {
        let fieldTempData = {};
        fieldTempData['fileUpload'] = { ...data['fileUpload'] };
        fieldTempData['fileUpload'].file = { keyName, imageUrl, file };
        return { ...data, ...fieldTempData };
      });
    } else {
      notify('Document Size should be less than 5 MB', 'warning');
    }
  };

  const onChangeTextfieldHandler = (field, e) => {
    if (typeof e.persist == 'function') e.persist();
    setFieldData(data => {
      let fieldTempData = {};
      fieldTempData[field] = { ...data[field] };
      fieldTempData[field].value = e?.target?.value;
      return { ...data, ...fieldTempData };
    });
  };

  const onChangeTextfieldErrorHandler = (field, message) => {
    setFieldData(data => {
      let fieldTempData = {};
      fieldTempData[field] = { ...data[field] };
      fieldTempData[field].errorMessage = message;
      return { ...data, ...fieldTempData };
    });
  };

  const getRecommends = async searchUser => {
    let reqBody = {
      searchUser: searchUser,
    };
    getRecommendationLoader(true);
    let res = await actionCreator.getRecommendsApi(reqBody);
    getRecommendationLoader(false);

    if (res?.user?.length > 0) {
      if (res?.user[0]?.isMinor) {
        setChildAcc(res?.user[0]);
        updateStatusOfChildField(true);
      } else {
        updateStatusOfChildField(false);
        onChangeTextfieldErrorHandler('childName', 'Invalid User');
      }
    } else if (res?.user?.length === 0) {
      onChangeTextfieldErrorHandler('childName', 'Invalid User');
      updateStatusOfChildField(false);
      setChildAcc(null);
    } else {
      updateStatusOfChildField(false);
      setChildAcc(null);
    }
  };

  const updateStatusOfChildField = status => {
    setFieldData(data => {
      let fieldTempData = {};
      fieldTempData['childName'] = { ...data['childName'] };
      fieldTempData['childName'].isValidUser = status;
      return { ...data, ...fieldTempData };
    });
  };

  const fileUpoadTrigger = () => {
    let fileInput = document.getElementById('child-profile-upload-id');
    fileInput.click();
  };

  const submitFormdata = async () => {
    let profilePic;
    if (fieldData?.fileUpload?.file?.file) {
      let profilePicResponse = await uploadDoc({
        file: fieldData?.fileUpload?.file?.file,
        key:
          fieldData?.fileUpload?.file?.key ||
          open.userName + (fieldData?.fileUpload?.file?.file?.name || ''),
      });

      if (profilePicResponse.status) {
        profilePic = profilePicResponse.data.uploaded_docs_url[0];
      }
    }

    let data = {
      email: fieldData.email.value,
      userName: fieldData.userName.value,
      password: fieldData.password.value,
      firstName: fieldData.firstName.value,
      lastName: fieldData.lastName.value || '',
      dob: fieldData.dob.value,
      guardianList: [],
      isMinor: true,
      profile_pic: profilePic || null,
      ref: profile?.nodes?.[0]?.referral_code,
    };

    let guardianList = [];
    let request = {
      from: { id: profile?._id, relation: fieldData.relation.value },
      to: { relation: '' },
    };
    guardianList.push(request);

    data.guardianList = guardianList;

    createChildrenAccount({ user: data }, ({ status }) => {
      if (status) {
        getChildrenRequest();
        getProfile();
        handleClose();
      }
    });
  };

  const setTimerMethod = () => {
    let otpTimer = getLocalData('otpTimer');
    let childEmail = JSON.parse(getLocalData('childEmail'));

    if (otpTimer && childEmail == fieldData.email.value) {
      let spentTime =
        (new Date().getTime() - new Date(JSON.parse(otpTimer)).getTime()) /
        1000;
      if (spentTime < 300) {
        setTimer(300 - parseInt(spentTime));
      } else {
        setLocalData('otpTimer', new Date());
        setTimer(300);
      }
    } else {
      setLocalData('otpTimer', new Date());
      setLocalData('childEmail', fieldData.email.value);
      setTimer(300);
    }
  };

  const sendOtpMethod = async () => {
    if (fieldData.email.value) {
      await sendOtp(
        {
          email: fieldData.email.value,
          ref: profile?.nodes?.[0]?.referral_code,
        },
        ({ otpSent, errorMessage }) => {
          if (otpSent || errorMessage == 'OTP already sent!') {
            setStage(1);
            setTimerMethod();
          }
        },
      );
    }
  };

  const submitOtp = async () => {
    let otp =
      fieldData.otp0.value +
      fieldData.otp1.value +
      fieldData.otp2.value +
      fieldData.otp3.value +
      fieldData.otp4.value +
      fieldData.otp5.value;

    await confirmOtp(
      { email: fieldData.email.value, otp },
      ({ user, registered, otpVerified }) => {
        if (otpVerified) {
          setStage(stage + 1);
        }
      },
    );
  };

  const onSubmkitHandler = e => {
    e?.preventDefault();

    let isInclude = childList?.filter(data => {
      return (
        data.userName == fieldData?.childName?.value ||
        data.email == fieldData?.childName?.value
      );
    });

    if (stage == 0) {
      if (value === 'preRegisteredUser') {
        if (!fieldData.childName.value) {
          onChangeTextfieldErrorHandler('childName', 'ChildName is required');
        } else if (!fieldData.childName.isValidUser) {
          onChangeTextfieldErrorHandler('childName', 'Invalid user');
        } else if (isInclude?.length !== 0) {
          onChangeTextfieldErrorHandler('childName', 'Child Already Added');
        } else if (
          fieldData?.childName?.isValidUser?.ProfileID == profile?._id
        ) {
          onChangeTextfieldErrorHandler('childName', "Can't add yourself");
        } else {
          onChangeTextfieldErrorHandler('childName', '');
          addChildRequest({
            relation: fieldData.relation.value,
            childId: childAcc.ProfileID,
            role: fieldData.role.value,
          });
          handleClose();
        }
      } else {
        sendOtpMethod();
      }
    }
  };

  const goPrevStage = () => {
    if (stage != 0) {
      setStage(stage - 1);
    }
  };

  const validateStep3 = async () => {
    let reqBody = {
      searchUser: fieldData.userName.value,
    };
    getRecommendationLoader(true);
    let res = await actionCreator.getRecommendsApi(reqBody);
    let isError = false;
    if (res?.user?.length > 0) {
      onChangeTextfieldErrorHandler('userName', 'User name already exist');
      isError = true;
    } else {
      onChangeTextfieldErrorHandler('userName', '');
    }
    if (fieldData.password?.value?.length < 8) {
      isError = true;
      onChangeTextfieldErrorHandler(
        'password',
        'Password must be of 8 characters atleast',
      );
    } else if (fieldData.password.value != fieldData.confirmPassword.value) {
      isError = true;
      onChangeTextfieldErrorHandler(
        'confirmPassword',
        "Password doesn't match",
      );
    } else {
      onChangeTextfieldErrorHandler('confirmPassword', '');
    }
    if (!isError) {
      submitFormdata();
    }
  };

  React.useEffect(() => {
    if (fieldData.childName.value) {
      const timeoutId = setTimeout(() => {
        getRecommends(fieldData.childName.value);
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
  }, [fieldData.childName.value]);

  React.useEffect(() => {
    if (timer > 0 && stage == 1) {
      var timerInterval = setTimeout(() => {
        setTimer(timer => {
          if (timer > 0) {
            return timer - 1;
          } else {
            clearTimeout(timerInterval);
            return 0;
          }
        });
      }, 1000);
    }
    if (stage != 1 || timer == 0) {
      clearTimeout(timerInterval);
      setTimer(0);
    }
  }, [timer, stage]);

  React.useEffect(() => {
    setFieldData(defaultFieldState);
  }, [value]);

  return (
    <>
      {sendOtpLoader ? <FullScreenLoader /> : null}
      <Box
        className={clsx(
          classes.createTaskContainer,
          'avoid-autofill-color-359DB6',
        )}>
        {stage == 0 ? (
          <form onSubmit={onSubmkitHandler}>
            <FormControl component="fieldset">
              {/* <FormLabel component="legend">Gender</FormLabel> */}
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={value}
                onChange={handleChange}>
                <FormControlLabel
                  value="preRegisteredUser"
                  control={<Radio />}
                  label="Add a child registered with Cogito Metaverse"
                />
                <FormControlLabel
                  value="newUser"
                  control={<Radio />}
                  label="Register a child"
                />
              </RadioGroup>
            </FormControl>
            <Grid container spacing={1}>
              {/* Child details - new child */}
              <Typography className={classes.heading}>Child Details</Typography>
              {value === 'preRegisteredUser' ? (
                <Grid
                  container
                  item
                  xs={12}
                  spacing={1}
                  justifyContent="space-between"
                  style={{
                    border: '2px solid #359DB6',
                    padding: '20px',
                    marginTop: '10px',
                    borderRadius: '10px',
                  }}>
                  <Grid
                    container
                    item
                    xs={12}
                    spacing={1}
                    justifyContent="space-between">
                    <Grid item xs={12} sm={6}>
                      <Box className={clsx(classes.fieldContainer)}>
                        <ContainerTitle
                          title={'Add Child Email/Username'}
                          className={clsx(classes.titleStyle)}
                        />

                        <CustomTextField
                          {...fieldData.childName}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {childAcc ? (
                                  <VerifiedUserRounded
                                    style={{
                                      color: '#1ba12b',
                                    }}
                                  />
                                ) : recommendationLoader ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : (
                                  <VerifiedUserRounded
                                    style={{
                                      color: '#73777B',
                                    }}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Box className={clsx(classes.fieldContainer)}>
                        <ContainerTitle
                          title={'Relation'}
                          className={clsx(classes.titleStyle)}
                        />
                        <CustomSelectField {...fieldData.relation} />
                      </Box>
                    </Grid>

                    {/* <Grid item xs={12}>
                      <Box className={clsx(classes.fieldContainer)}>
                        <ContainerTitle
                          title={'Role'}
                          className={clsx(classes.titleStyle)}
                        />
                        <CustomSelectField {...fieldData.role} />
                      </Box>
                    </Grid> */}
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  item
                  xs={12}
                  spacing={1}
                  justifyContent="space-between"
                  style={{
                    border: '2px solid #359DB6',
                    padding: '20px',
                    marginTop: '10px',
                    borderRadius: '10px',
                  }}>
                  <Grid item xs={12} sm={6}>
                    <Box className={clsx(classes.fieldContainer)}>
                      <ContainerTitle
                        title={'First Name'}
                        className={clsx(classes.titleStyle)}
                      />
                      <CustomTextField {...fieldData.firstName} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box className={clsx(classes.fieldContainer)}>
                      <ContainerTitle
                        title={'Last Name'}
                        className={clsx(classes.titleStyle)}
                      />
                      <CustomTextField {...fieldData.lastName} />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box className={clsx(classes.fieldContainer)}>
                      <ContainerTitle
                        title={'Email ID'}
                        className={clsx(classes.titleStyle)}
                      />
                      <CustomTextField {...fieldData.email} />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box className={clsx(classes.fieldContainer)}>
                      <ContainerTitle
                        title={'DOB'}
                        className={clsx(classes.titleStyle)}
                      />

                      <CustomTextField {...fieldData.dob} />
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box className={clsx(classes.fieldContainer)}>
                      <ContainerTitle
                        title={'Your Relation'}
                        className={clsx(classes.titleStyle)}
                      />
                      <CustomSelectField {...fieldData.relation} />
                    </Box>
                  </Grid>

                  {/* <Grid item xs={12} sm={6}>
                    <Box className={clsx(classes.fieldContainer)}>
                      <ContainerTitle
                        title={'Role'}
                        className={clsx(classes.titleStyle)}
                      />
                      <CustomSelectField {...fieldData.role} />
                    </Box>
                  </Grid> */}

                  <Grid item xs={12} sm={6}>
                    <Box
                      className={clsx(classes.fieldContainer)}
                      style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <input
                        {...fieldData.fileUpload}
                        className={classes.inputFieldStyle}
                        onChange={imageCaptureMethod}
                      />
                      {fieldData.fileUpload?.file?.imageUrl ? (
                        <Box className={classes.imageContainer}>
                          <img
                            src={fieldData.fileUpload?.file?.imageUrl}
                            alt={fieldData.fileUpload?.file?.file?.name}
                            className={classes.imageStyle}
                            onClick={fileUpoadTrigger}
                          />
                          <Typography>
                            {maxStringLength(
                              fieldData.fileUpload?.file?.file?.name,
                              18,
                            )}
                          </Typography>
                        </Box>
                      ) : (
                        <BorderButton
                          style={{ height: '100%' }}
                          type={'button'}
                          text={'Upload Child Image (optional)'}
                          className={classes.uploadChildProfileImageStyle}
                          onClick={fileUpoadTrigger}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box className={clsx(classes.submitContainer)}>
                  <BorderButton text={'Submit'} type={'submit'} />
                </Box>
              </Grid>
            </Grid>
          </form>
        ) : stage == 1 ? (
          <>
            <form onSubmit={onSubmkitHandler}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <BaseButton
                    onClick={goPrevStage}
                    text={
                      <Box className={classes.backContainer}>
                        <ArrowBackIosIcon className={classes.backIconStyle} />{' '}
                        <Typography className={classes.backTextStyle}>
                          Back
                        </Typography>
                      </Box>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box className={clsx(classes.verifyTitleContainer)}>
                    <ContainerTitle
                      title={'Verify Email Address'}
                      className={clsx(classes.verifyTitleTextStyle)}
                    />
                  </Box>
                  <Box className={clsx(classes.verifyTitleContainer)}>
                    <ContainerTitle
                      title={
                        <Typography
                          className={clsx(
                            classes.verifyTitleDescriptionTextStyle,
                          )}>
                          Enter the verification code we just sent you on your
                          email address:{' '}
                          <span style={{ color: '#B08720' }}>
                            {fieldData.email.value}
                          </span>
                        </Typography>
                      }
                      className={clsx(
                        classes.verifyTitleDescriptionContainerStyle,
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={clsx(classes.otpInputBoxContainer)}>
                    <CustomTextField
                      {...fieldData.otp0}
                      className={clsx(classes.otpTextFieldStyle)}
                    />
                    <CustomTextField
                      {...fieldData.otp1}
                      className={clsx(classes.otpTextFieldStyle)}
                    />
                    <CustomTextField
                      {...fieldData.otp2}
                      className={clsx(classes.otpTextFieldStyle)}
                    />
                    <CustomTextField
                      {...fieldData.otp3}
                      className={clsx(classes.otpTextFieldStyle)}
                    />
                    <CustomTextField
                      {...fieldData.otp4}
                      className={clsx(classes.otpTextFieldStyle)}
                    />
                    <CustomTextField
                      {...fieldData.otp5}
                      className={clsx(classes.otpTextFieldStyle)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={clsx(classes.verifyTitleContainer)}>
                    <ContainerTitle
                      title={
                        <Typography
                          className={clsx(
                            classes.verifyTitleDescriptionTextStyle,
                          )}
                          style={{ color: '#8F8D8D' }}>
                          If you didn’t receive a code?{' '}
                          {!timer ? (
                            <BaseButton
                              text={'Resend.'}
                              style={{ color: '#F0795C' }}
                              onClick={sendOtpMethod}
                            />
                          ) : (
                            `Resend after ${getTimeFromSeconds(timer)}`
                          )}
                        </Typography>
                      }
                      className={clsx(
                        classes.verifyTitleDescriptionContainerStyle,
                      )}
                    />
                  </Box>
                </Grid>
                {fieldData.otp0.value &&
                fieldData.otp1.value &&
                fieldData.otp2.value &&
                fieldData.otp3.value &&
                fieldData.otp4.value &&
                fieldData.otp5.value ? (
                  <Grid item xs={12}>
                    <Box className={clsx(classes.submitContainer)}>
                      <BorderButton text={'Submit'} onClick={submitOtp} />
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            </form>
          </>
        ) : stage == 2 ? (
          <>
            <form onSubmit={onSubmkitHandler}>
              <Grid container spacing={1}>
                {/* <Grid item xs={12}>
                   <BaseButton
                     onClick={goPrevStage}
                     text={
                       <Box className={classes.backContainer}>
                         <ArrowBackIosIcon className={classes.backIconStyle} />{" "}
                         <Typography className={classes.backTextStyle}>
                           Back
                         </Typography>
                       </Box>
                     }
                   />
                 </Grid> */}
                <Grid item xs={12}>
                  <Box className={clsx(classes.verifyTitleContainer)}>
                    <ContainerTitle
                      title={'New Password'}
                      className={clsx(classes.verifyTitleTextStyle)}
                    />
                  </Box>
                  <Box className={clsx(classes.verifyTitleContainer)}>
                    <ContainerTitle
                      title={
                        <Typography
                          className={clsx(
                            classes.verifyTitleDescriptionTextStyle,
                          )}>
                          Below you have to type in your new password
                        </Typography>
                      }
                      className={clsx(
                        classes.verifyTitleDescriptionContainerStyle,
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    className={clsx(
                      classes.fieldContainer,
                      classes.centerField,
                    )}>
                    <Box className={clsx(classes.stage3InputWidth)}>
                      <CustomTextField
                        {...fieldData.userName}
                        className={classes.stage3InputField}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    className={clsx(
                      classes.fieldContainer,
                      classes.centerField,
                    )}>
                    <Box className={clsx(classes.stage3InputWidth)}>
                      <CustomTextField
                        {...fieldData.password}
                        className={classes.stage3InputField}
                        type={
                          fieldData.passwordType.value ? 'password' : 'text'
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {fieldData.passwordType.value ? (
                                <BaseButton
                                  text={<VisibilityIcon />}
                                  onClick={() =>
                                    onChangeTextfieldHandler('passwordType', {
                                      target: { value: false },
                                    })
                                  }
                                />
                              ) : (
                                <BaseButton
                                  text={<VisibilityOffIcon />}
                                  onClick={() =>
                                    onChangeTextfieldHandler('passwordType', {
                                      target: { value: true },
                                    })
                                  }
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    className={clsx(
                      classes.fieldContainer,
                      classes.centerField,
                    )}>
                    <Box className={clsx(classes.stage3InputWidth)}>
                      <CustomTextField
                        {...fieldData.confirmPassword}
                        className={classes.stage3InputField}
                        type={
                          fieldData.passwordType.value ? 'password' : 'text'
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {fieldData.passwordType.value ? (
                                <BaseButton
                                  text={<VisibilityIcon />}
                                  onClick={() =>
                                    onChangeTextfieldHandler('passwordType', {
                                      target: { value: false },
                                    })
                                  }
                                />
                              ) : (
                                <BaseButton
                                  text={<VisibilityOffIcon />}
                                  onClick={() =>
                                    onChangeTextfieldHandler('passwordType', {
                                      target: { value: true },
                                    })
                                  }
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className={clsx(classes.submitContainer)}>
                    <BorderButton text={'Submit'} onClick={validateStep3} />
                  </Box>
                </Grid>
              </Grid>
            </form>
          </>
        ) : null}
      </Box>
    </>
  );
};
const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    recommendationLoader: userReducer?.loadings?.getRecommendation,
    childList: userReducer?.childList,
    sendOtpLoader: userReducer?.loadings?.sendOtp,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    notify: data => {
      dispatch(actionCreator.notify(data));
    },
    getRecommendationLoader: status => {
      dispatch(actionCreator.setGetRecommendation(status));
    },
    sendOtp: (data, callBack) => {
      dispatch(actionCreator.sendSignupOtp(data, callBack));
    },
    confirmOtp: (data, callBack) => {
      dispatch(actionCreator.confirmOTP(data, callBack));
    },
    createChildrenAccount: (data, callBack) => {
      dispatch(actionCreator.createChildrenAccount(data, callBack));
    },
    getChildrenRequest: () => {
      dispatch(actionCreator.getChildrenRequest());
    },
    uploadDoc: data => actionCreator.uploadDoc(data),
    addChildRequest: data => dispatch(actionCreator.addChildRequest(data)),
    getProfile: () => dispatch(actionCreator.getProfile()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateChildModal);
