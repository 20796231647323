import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import ContainerTitle from '../../../components/common/title/ContainerTitle';
import BorderButton from '../../../components/buttons/BorderButton';
import * as actionCreator from '../../../store/action';
import { getPreviousDate, yyyyMmDd } from '../../../utils/timeMethods';
import CustomTextField from '../../../components/muiInputFields.js/CustomTextField';
import React from 'react';
import { connect } from 'react-redux';
import { constant } from '../../../utils/constant';
import CustomSelectField from '../../../components/muiInputFields.js/CustomSelectFiled';
import { VerifiedUserRounded, ViewStream } from '@material-ui/icons';

import { isValueExist } from '../../../utils/methods';
import SmallLoader from '../../../components/loader/SmallLoader';

const useStyle = makeStyles(theme => ({
  createTaskContainer: {
    width: '100%',
    maxWidth: '800px',
  },
  fieldContainer: {
    position: 'relative',
    background: '#A3CED9',
    borderRadius: '10px',
    padding: '5px 15px',
  },
  titleStyle: {
    color: '#333333',
    fontSize: '1rem',
    fontWeight: 550,
  },
  assignToList: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginTop: 12,
  },
  chipStyle: {
    margin: '0 5px',
    padding: 4,
    border: '1.3px solid #074D5F',
    minWidth: 100,
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
      border: '1.3px solid #888',
    },
  },
  unSelectedChipStyle: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  selectedChipStyle: {
    backgroundColor: '#A3D4E0',
    '&:hover': {
      backgroundColor: '#A3D4E0',
    },
    '&:focus': {
      backgroundColor: '#A3D4E0',
    },
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12,
  },
  errorStyle: {
    color: 'red',
    fontSize: '0.9rem',
    fontWeight: 500,
    position: 'absolute',
    bottom: -25,
  },
  selectedDateContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  inputFieldStyle: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: -10000,
  },
  uploadChildProfileImageStyle: {
    width: '100%',
    backgroundColor: 'transparent',
    color: '#333',
    border: '2px solid #359DB6',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  imageStyle: {
    width: '100%',
    maxWidth: 500,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  addCircleSharpIcon: {
    fontSize: '1.3rem',
    color: '#333',
    marginLeft: 5,
  },
  addGuardianContainerStyle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
  },
  guardianListContainer: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  guardianAddedContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1.5px solid #359DB6',
    borderRadius: 18,
    padding: 2,
  },
  guardianInnerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  profileIconStyle: {
    fontSize: '2rem',
    marginRight: '0.5rem',
    [theme.breakpoints.up(constant.breakLaptopAuthPoint)]: {
      fontSize: '2.5rem',
      marginRight: '0.7rem',
    },
    [theme.breakpoints.down(constant.breakMobileAuthPoint)]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.down(constant.breakSmallMobileAuthPoint)]: {
      fontSize: '1.6rem',
    },
  },
  backContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  backIconStyle: {
    fontSize: '1rem',
  },
  backTextStyle: {
    fontSize: '1rem',
  },
  verifyTitleContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  verifyTitleTextStyle: {
    color: '#155B6B',
  },
  verifyTitleDescriptionContainerStyle: {
    color: '#155B6B',
    display: 'flex',
    justifyContent: 'center',
  },
  verifyTitleDescriptionTextStyle: {
    textAlign: 'center',
    color: '#155B6B',
    fontSize: '0.8rem',
    maxWidth: 422,
  },
  otpInputBoxContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  otpTextFieldStyle: {
    width: '2.5rem',
    margin: '0 3px',
    backgroundColor: '#359DB6',
    borderRadius: 5,
    textAlign: 'center',
  },
  stage3InputField: {
    backgroundColor: '#359DB6',
  },
  stage3InputWidth: {
    width: '60%',
  },
  centerField: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const CreateGuardianModal = props => {
  const {
    open,
    handleClose,
    notify,
    getRecommendationLoader,
    recommendationLoader,
    getChildList,
    uploadDoc,
    profile,
    childList,
    createFamilyMember,
    loadings,
    setUserLoading,
  } = props;
  const theme = useTheme();
  const classes = useStyle(theme);

  const [fieldData, setFieldData] = React.useState({
    firstName: {
      value: '',
      errorMessage: '',
      required: 'true',
      onChange: e => {
        onChangeTextfieldHandler('firstName', e);
      },
    },
    lastName: {
      value: '',
      errorMessage: '',
      required: 'true',
      onChange: e => {
        onChangeTextfieldHandler('lastName', e);
      },
    },
    dob: {
      value: '',
      errorMessage: '',
      type: 'date',
      inputProps: {
        min: yyyyMmDd(getPreviousDate(16)),
        max: yyyyMmDd(new Date()),
      },
      minDate: '2022-10-29',
      maxDate: yyyyMmDd(new Date()),
      required: 'true',
      onChange: e => {
        onChangeTextfieldHandler('dob', e);
      },
    },
    guardianName: {
      value: '',
      errorMessage: '',
      isValidUser: false,
      onChange: e => {
        updateStatusOfGuardianField(false);
        onChangeTextfieldHandler('guardianName', e);
        onChangeTextfieldErrorHandler('guardianName', '');
      },
    },
    guardianRelation: {
      value: '',
      options: [
        { value: 'father', label: 'Father' },
        { value: 'mother', label: 'Mother' },
        { value: 'guardian', label: 'Guardian' },
      ],
      errorMessage: '',
      onChange: e => {
        onChangeTextfieldHandler('guardianRelation', e);
        onChangeTextfieldErrorHandler('guardianRelation', '');
      },
    },
    childRelation: {
      value: '',
      options: [
        { value: 'son', label: 'Son' },
        { value: 'daughter', label: 'Daughter' },
        { value: 'ward', label: 'Ward' },
      ],
      errorMessage: '',
      required: 'true',
      onChange: e => {
        onChangeTextfieldHandler('childRelation', e);
        onChangeTextfieldErrorHandler('childRelation', '');
      },
    },
    guardianList: {
      value: [],
      errorMessage: '',
    },
  });

  const [childListData, setChildListData] = React.useState([]);
  const [selectedChild, setSelectedChild] = React.useState(null);
  const [selectedGuardian, setSelectedGuardian] = React.useState(null);

  const onChangeTextfieldHandler = (field, e) => {
    if (typeof e.persist == 'function') e.persist();
    setFieldData(data => {
      let fieldTempData = {};
      fieldTempData[field] = { ...data[field] };
      fieldTempData[field].value = e?.target?.value;
      return { ...data, ...fieldTempData };
    });
  };

  const onChangeTextfieldErrorHandler = (field, message) => {
    setFieldData(data => {
      let fieldTempData = {};
      fieldTempData[field] = { ...data[field] };
      fieldTempData[field].errorMessage = message;
      return { ...data, ...fieldTempData };
    });
  };

  const getRecommends = async searchUser => {
    let reqBody = {
      searchUser: searchUser,
    };
    getRecommendationLoader(true);
    let res = await actionCreator.getRecommendsApi(reqBody);
    getRecommendationLoader(false);
    let guardianId = res?.user?.[0]?.ProfileID;
    setSelectedGuardian(res?.user?.[0]);
    let alreadyExist = isValueExist(
      guardianId,
      selectedChild?.data?.family || [],
      '_id',
    ).filter(data => {
      return data?.status == 'Accept' || data?.status == 'Waiting for Approval';
    });

    if (guardianId == profile?._id) {
      onChangeTextfieldErrorHandler('guardianName', "Can't add yourself");
      updateStatusOfGuardianField(false);
    } else if (alreadyExist.length) {
      onChangeTextfieldErrorHandler(
        'guardianName',
        alreadyExist?.[0]?.status == 'Accept'
          ? 'Already a guardian'
          : 'Request already sent',
      );

      updateStatusOfGuardianField(false);
    } else if (res?.user?.length > 0) {
      if (res?.user?.[0].isMinor === false) {
        updateStatusOfGuardianField(res?.user?.[0]);
      } else {
        onChangeTextfieldErrorHandler('guardianName', 'Invalid User');
        updateStatusOfGuardianField(false);
      }
    } else {
      onChangeTextfieldErrorHandler('guardianName', 'Invalid User');
      updateStatusOfGuardianField(false);
    }
  };

  const updateStatusOfGuardianField = status => {
    setFieldData(data => {
      let fieldTempData = {};
      fieldTempData['guardianName'] = { ...data['guardianName'] };
      fieldTempData['guardianName'].isValidUser = status;
      return { ...data, ...fieldTempData };
    });
  };

  const fileUpoadTrigger = () => {
    let fileInput = document.getElementById('child-profile-upload-id');
    fileInput.click();
  };

  const submitFormdata = async () => {
    let body = {
      from: {
        id: selectedChild?.data?._id,
        relation: fieldData?.childRelation?.value,
      },
      to: {
        id: selectedGuardian?.ProfileID,
        relation: fieldData?.guardianRelation?.value,
      },
    };
    setUserLoading(true);
    let response = await createFamilyMember(body);
    // changes
    if (response.status) {
      notify('Updated', 'success');
      setUserLoading(false);
      getChildList();
    } else {
      setUserLoading(false);
      notify('Failed to update', 'error');
    }
    handleClose();
  };

  const onSubmkitHandler = e => {
    e?.preventDefault();
    submitFormdata();
  };

  const addGuardianMethod = () => {
    if (
      fieldData.guardianName.isValidUser &&
      fieldData.guardianRelation.value
    ) {
      let isInclude = fieldData.guardianList?.value.map(
        data =>
          data.ProfileID == fieldData?.guardianName?.isValidUser?.ProfileID,
      );
      if (isInclude?.length) {
        onChangeTextfieldErrorHandler('guardianName', 'Guardian Already Added');
      } else {
        setFieldData(data => {
          let updatedData = {};
          updatedData['guardianList'] = data.guardianList;
          updatedData['guardianList'].value = [
            ...data.guardianList.value,
            {
              ...fieldData?.guardianName?.isValidUser,
              Relation: data.guardianRelation.value,
            },
          ];
          updatedData['guardianName'] = data.guardianName;
          updatedData['guardianName'].value = '';
          updatedData['guardianName'].isValidUser = false;

          updatedData['guardianRelation'] = data.guardianRelation;
          updatedData['guardianRelation'].value = '';
          return { ...data, updatedData };
        });
        onChangeTextfieldErrorHandler('guardianName', '');
      }

      onChangeTextfieldErrorHandler('guardianRelation', '');
    } else {
      if (!fieldData.guardianName.value) {
        onChangeTextfieldErrorHandler('guardianName', 'Guardian is required');
      } else if (
        fieldData?.guardianName?.errorMessage === "Can't add yourself"
      ) {
        onChangeTextfieldErrorHandler('guardianName', "Can't add yourself");
      } else if (!fieldData.guardianName.isValidUser) {
        onChangeTextfieldErrorHandler('guardianName', 'Invalid user');
      } else {
        onChangeTextfieldErrorHandler('guardianName', '');
      }

      if (!fieldData.guardianRelation.value) {
        onChangeTextfieldErrorHandler(
          'guardianRelation',
          'Relation is required',
        );
      } else {
        onChangeTextfieldErrorHandler('guardianRelation', '');
      }
    }
  };

  const removeGuardian = guardian => {
    setFieldData(data => {
      let fieldTempData = {};
      fieldTempData.guardianList = { ...data.guardianList };
      fieldTempData.guardianList.value =
        fieldTempData.guardianList.value.filter(
          data => data.ProfileID != guardian.ProfileID,
        );
      return { ...data, ...fieldTempData };
    });
  };

  React.useEffect(() => {
    if (fieldData.guardianName.value) {
      const timeoutId = setTimeout(() => {
        getRecommends(fieldData.guardianName.value);
        updateStatusOfGuardianField(false);
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
  }, [fieldData.guardianName.value]);

  React.useEffect(() => {
    setFieldData(data => {
      let tempDate = { ...data };
      tempDate.firstName.value = open?.firstName;
      tempDate.lastName.value = open?.lastName;
      tempDate.dob.value = open?.dateOfBirth;
      // tempDate.profileImage.value = open?.profile_pic;
      return { ...tempDate };
    });
  }, []);

  React.useEffect(() => {
    setChildListData(
      childList.length > 0
        ? childList?.map(data => {
            return {
              value: {
                firstName: data.firstName,
                lastName: data.lastName,
                dateOfBirth: data.dateOfBirth,
                data: data,
              },
              label: data.firstName + ' ' + data.lastName,
            };
          })
        : [
            {
              value: null,
              label: 'No Child',
            },
          ],
    );
  }, [childList]);

  React.useEffect(() => {
    setFieldData(data => {
      let tempDate = { ...data };
      tempDate.firstName.value = selectedChild?.firstName;
      tempDate.lastName.value = selectedChild?.lastName;
      tempDate.dob.value = selectedChild?.dateOfBirth;
      return { ...tempDate };
    });
  }, [selectedChild]);

  React.useEffect(() => {
    if (fieldData.guardianName.value) {
      getRecommends(fieldData.guardianName.value);
    }
    onChangeTextfieldErrorHandler('guardianName', '');
  }, [selectedChild]);

  return (
    <>
      <Box
        className={clsx(
          classes.createTaskContainer,
          'avoid-autofill-color-359DB6',
        )}>
        <form onSubmit={onSubmkitHandler}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box
                className={clsx(classes.fieldContainer)}
                style={{ position: 'relative', top: '-3px' }}>
                <ContainerTitle
                  title={'Select Child'}
                  className={clsx(classes.titleStyle)}
                />
                <CustomSelectField
                  options={childListData}
                  onChange={e => setSelectedChild(e.target.value)}
                />
              </Box>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} sm={6}>
                <Box className={clsx(classes.fieldContainer)}>
                  <ContainerTitle
                    title={'Add Parents Email/Username'}
                    className={clsx(classes.titleStyle)}
                  />
                  <CustomTextField
                    {...fieldData.guardianName}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {fieldData.guardianName.isValidUser &&
                          fieldData.guardianName.value ? (
                            <VerifiedUserRounded
                              style={{
                                color: '#1ba12b',
                              }}
                            />
                          ) : recommendationLoader ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            <VerifiedUserRounded
                              style={{
                                color: '#73777B',
                              }}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box
                  className={clsx(classes.fieldContainer)}
                  style={{ position: 'relative', top: '-3px' }}>
                  <ContainerTitle
                    title={'Relation of Parent'}
                    className={clsx(classes.titleStyle)}
                  />

                  <CustomSelectField {...fieldData.guardianRelation} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box className={clsx(classes.fieldContainer)}>
                  <ContainerTitle
                    title={'Kids Relation'}
                    className={clsx(classes.titleStyle)}
                  />
                  <CustomSelectField {...fieldData.childRelation} />
                </Box>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box className={clsx(classes.submitContainer)}>
                <BorderButton
                  padding={loadings.addParent ? '0.5% 0 1% 2%' : null}
                  disabled={
                    selectedChild === 0 ||
                    selectedChild === null ||
                    loadings.addParent ||
                    fieldData.guardianName.isValidUser === false ||
                    fieldData.guardianName.errorMessage ||
                    fieldData.guardianRelation.value == ''
                      ? true
                      : false
                  }
                  text={loadings.addParent ? <SmallLoader /> : 'Add'}
                  type={'submit'}

                  // onClick={submitFormdata}
                />
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    recommendationLoader: userReducer?.loadings?.getRecommendation,
    childList: userReducer.childList,
    loadings: userReducer.loadings,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    notify: (data, variant) => {
      dispatch(actionCreator.notify(data, variant));
    },
    getRecommendationLoader: status => {
      dispatch(actionCreator.setGetRecommendation(status));
    },
    uploadDoc: data => actionCreator.uploadDoc(data),
    getChildList: () => {
      dispatch(actionCreator.getChildList());
    },
    createFamilyMember: data => actionCreator.createFamilyMember(data),
    setUserLoading: data => {
      dispatch(
        actionCreator.setUserLoading({
          addParent: data,
        }),
      );
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateGuardianModal);
