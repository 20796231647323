import { Box, makeStyles, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import ContainerTitle from '../../../components/common/title/ContainerTitle';
import BorderButton from '../../../components/buttons/BorderButton';
import * as actionCreator from '../../../store/action';
import React from 'react';
import { connect } from 'react-redux';

const useStyle = makeStyles(theme => ({
  createTaskContainer: {
    width: '100%',
    maxWidth: '800px',
  },
  desclaimerStyle: {
    color: '#333333',
    fontSize: '1.2rem',
    fontWeight: 600,
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
  },
  cancelClass: {
    marginRight: 16,
    color: '#359DB6',
    border: '2px solid #359DB6',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const DeleteChildModal = props => {
  const { open, handleClose, notify, profile, removeRelation } = props;
  const theme = useTheme();
  const classes = useStyle(theme);

  const handleRelationship = () => {
    let fromFamilyData = profile?.family?.filter(
      data => open?._id == data?._id,
    );
    fromFamilyData?.map(data => {
      let request = {
        from: '',
        to: '',
        status: 'Archived',
      };
      if (data?.request_type == 'Sent') {
        request.from = profile?._id;
        request.to = data?._id;
      } else {
        request.from = data?._id;
        request.to = profile?._id;
      }
      removeRelation(request);
    });
    handleClose();
  };
  return (
    <>
      <Box
        className={clsx(
          classes.createTaskContainer,
          'avoid-autofill-color-359DB6',
        )}>
        <Box>
          <ContainerTitle
            title={`Remove ${open.firstName} ${open.lastName} from children list?`}
            className={clsx(classes.desclaimerStyle)}
          />
        </Box>
        <Box className={clsx(classes.actionContainer)}>
          <BorderButton
            text={'Cancel'}
            onClick={handleClose}
            className={classes.cancelClass}
          />
          <BorderButton text={'Confirm'} onClick={handleRelationship} />
        </Box>
      </Box>
    </>
  );
};
const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    recommendationLoader: userReducer?.loadings?.getRecommendation,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    notify: (data, variant) => {
      dispatch(actionCreator.notify(data, variant));
    },
    getRecommendationLoader: status => {
      dispatch(actionCreator.setGetRecommendation(status));
    },
    updateChildData: data => actionCreator.updateChildData(data),
    uploadDoc: data => actionCreator.uploadDoc(data),
    getChildList: () => {
      dispatch(actionCreator.getChildList());
    },
    removeRelation: data => {
      dispatch(actionCreator.updateChildRequest(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DeleteChildModal);
