import {
  Box,
  Checkbox,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import BorderButton from '../../../components/buttons/BorderButton';
import * as actionCreator from '../../../store/action';

import React from 'react';
import { connect } from 'react-redux';
import { constant } from '../../../utils/constant';

const useStyle = makeStyles(theme => ({
  heading: {
    color: '#17505E',
    fontWeight: 'bold',
    marginTop: '2%',
  },
  createTaskContainer: {
    width: '100%',
    maxWidth: '800px',
    // justifyContent: 'center',
  },
  submitContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 12,
  },
}));

const ApproveKYCModal = props => {
  const { open, handleClose, notify, sendOtp, profile, childList, approveKyc } =
    props;
  const theme = useTheme();
  const classes = useStyle(theme);
  const [submitEnable, setSubmitEnable] = React.useState(false);

  const handleApproveKyc = () => {
    let data = {
      id: open._id,
      status: true,
    };
    approveKyc(data);
    handleClose();
  };
  return (
    <>
      <Box
        className={clsx(
          classes.createTaskContainer,
          'avoid-autofill-color-359DB6',
        )}>
        <Grid
          container
          item
          xs={12}
          spacing={1}
          justifyContent="space-between"
          style={{
            border: '2px solid #359DB6',
            padding: '20px',
            marginTop: '10px',
            height: '30vh',
            overflowY: 'scroll',
            borderRadius: '10px',
          }}>
          {/* <Grid
            container
            item
            xs={12}
            spacing={1}
            justifyContent="space-between"> */}
          <Typography>
            <text style={{ fontWeight: 'bold' }}>
              By granting approval for your child to open a Cogito account and
              access investment products, you acknowledge and accept the
              following terms and conditions:
            </text>
            <br /> <br />
            - If required your child will be required to provide valid Know Your
            Customer (KYC) documentation to Cogito. This may include proof of
            identity, address, and other relevant information. Until then you
            confirm the child is your ward.
            <br /> <br />
            - You understand that Cogito has certain policies and procedures in
            place to protect your child's account and privacy, and you agree to
            comply with these policies and procedures.
            <br /> <br />
            - You acknowledge that your child will have access to investment
            products offered by Cogito, and that your child may be subject to
            certain terms and conditions associated with these products.
            <br /> <br />
            - You understand that investing involves risks, including the risk
            of loss, and you agree to monitor your child's investments and make
            investment decisions with your child based on your risk tolerance
            and investment goals.
            <br /> <br />
            - You acknowledge that your child will be subject to Cogito’s rules
            and regulations, and you agree to abide by these rules and
            regulations.
            <br /> <br />
            - You agree to indemnify and hold harmless Cogito and its affiliates
            from any claims, damages, or losses arising from your child's use of
            the account or investment products.
            <br /> <br />- By signing below, you acknowledge that you have read
            and understand this disclaimer and agree to the terms and conditions
            outlined above. You also confirm that your child has valid KYC
            documentation and is responsible for any and all transactions made
            on their account, including investments.
          </Typography>
          {/* </Grid> */}
        </Grid>
        <Grid container style={{ marginTop: '3%', alignItems: 'center' }}>
          <Grid item>
            <Checkbox
              color="primary"
              checked={submitEnable}
              onChange={e => setSubmitEnable(!submitEnable)}
            />
          </Grid>
          <Grid item xs={10}>
            <Typography>I agree to the above terms and conditions.</Typography>
          </Grid>
          <Grid xs={12} justifyContent="center">
            <Box className={clsx(classes.submitContainer)}>
              <BorderButton
                text={'Approve KYC'}
                type={'submit'}
                disabled={!submitEnable}
                onClick={handleApproveKyc}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
const mapStateToProps = ({ userReducer, authReducer }) => {
  return {
    profile: userReducer.profile,
    childList: userReducer?.childList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    notify: data => {
      dispatch(actionCreator.notify(data));
    },
    approveKyc: data => dispatch(actionCreator.approveKyc(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ApproveKYCModal);
