import { Button, ButtonBase, makeStyles, Typography } from '@material-ui/core';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import BorderButton from '../../../components/buttons/BorderButton';
import { constant } from '../../../utils/constant';
import './header.css';
import LOGO from '../../../assets/images/logo-light.svg';
import { useEffect } from 'react';
import { Box } from '@mui/system';
import CommonModel from '../model/CommonModel';
import RedirectInfoPage from '../../../pages/preLoggedIn/redirectInfo/RedirectInfoPage';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    width: '67%',
    display: 'flex',
    justifyContent: 'flex-end',

    [theme.breakpoints.down(960)]: {
      width: '85%',
    },
    [theme.breakpoints.down(850)]: {
      width: '80%',
    },
    [theme.breakpoints.down(600)]: {
      width: '76%',
    },
    [theme.breakpoints.down(570)]: {
      width: '72%',
    },
    [theme.breakpoints.down(450)]: {
      width: '68%',
    },
    [theme.breakpoints.down(400)]: {
      width: '62%',
    },
  },
}));
const Header = props => {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [redirectInfo, setRedirectInfo] = useState(false);
  const classes = useStyles();
  const handleClick = () => setClick(!click);
  const history = useHistory();
  const redirect = route => {
    history.push(route);
  };
  const closeMobileMenu = () => setClick(false);
  const showButton = () => {
    if (window.innerWidth <= 900) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  const handleRequestInfo = () => {
    setRedirectInfo(!redirectInfo);
  };

  useEffect(() => {
    showButton();
  }, []);
  window.addEventListener('resize', showButton);
  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener('scroll', changeBackground);

  return (
    <>
      <CommonModel
        title="Please Note"
        open={redirectInfo}
        handleClose={() => handleRequestInfo()}
        body={
          <RedirectInfoPage
            open={redirectInfo}
            handleClose={() => handleRequestInfo()}
          />
        }
      />
      <nav className={navbar ? 'navbar active' : 'navbar'}>
        <div className="navbar-container" style={{}}>
          <div>
            <Link to="/" onClick={closeMobileMenu}>
              <img
                src={LOGO}
                alt="LOGO"
                style={{
                  width: '45px',
                  paddingTop: '10px',
                  color: '#359DB6',
                  alignItem: 'left',
                }}
              />
            </Link>
          </div>

          {!button && (
            <div className={classes.buttonContainer}>
              <BorderButton
                className={classes.buttonContainer}
                text="Sign up"
                id="Sign-Up"
                //   {...constant.lightButtonStyle}
                width="100px"
                style={{
                  backgroundColor: 'transparent',
                  color: '#359DB6',
                  border: '1.5px solid #359DB6',
                  borderRadius: '12px',
                  margin: '0.5rem 0.8rem 0rem 0rem',
                }}
                onClick={() => {
                  // window.open(
                  //   `${process.env.REACT_APP_WEBSITE_HOST}/signup`,
                  //   '_blank',
                  // );
                  // redirect(constant.routes.redirectInfo);
                  handleRequestInfo();
                }}
              />

              <div className="menu-icon" onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
              </div>
            </div>
          )}
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>

          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <Box className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                HOME
              </Link>
            </Box>
            <Box className="nav-item">
              <Link
                to="/aboutUs"
                className="nav-links"
                onClick={closeMobileMenu}>
                ABOUT US
              </Link>
            </Box>
            <Box className="nav-item">
              <Link
                to="/ourStory"
                className="nav-links"
                onClick={closeMobileMenu}>
                OUR STORY
              </Link>
            </Box>
            <Box className="nav-item">
              <Link
                to="/contactUs"
                className="nav-links"
                // style={{ textDecoration: 'none' }}
                onClick={closeMobileMenu}>
                CONTACT
              </Link>
            </Box>
            {/* <Box className="nav-item">
              <Link
                to="/careers"
                className="nav-links"
                onClick={closeMobileMenu}>
                CAREERS
              </Link>
            </Box> */}
            {!button && (
              <Box className="nav-item">
                <Link
                  to={constant.routes.accountType}
                  className="nav-links"
                  onClick={closeMobileMenu}>
                  LOGIN
                </Link>
              </Box>
            )}
          </ul>
          {button && (
            <>
              <BorderButton
                className={classes.buttonContainer}
                text="Sign up"
                id="Sign-up"
                {...constant.transparentButton}
                width="100px"
                style={{
                  margin: '0.5rem 0.8rem 0rem 0rem',
                }}
                onClick={() => {
                  // window.open(
                  //   `${process.env.REACT_APP_WEBSITE_HOST}/signup`,
                  //   '_blank',
                  // );
                  // redirect(constant.routes.redirectInfo);
                  handleRequestInfo();
                }}
              />

              <BorderButton
                className={classes.buttonContainer}
                text="Log in "
                id="Log-In"
                //   {...constant.lightButtonStyle}
                width="80px"
                style={{
                  margin: '0.5rem 0rem 0rem 0rem',
                }}
                onClick={() => {
                  redirect(constant.routes.accountType);
                }}
              />
            </>
          )}
        </div>
      </nav>
    </>
  );
};
export default Header;
